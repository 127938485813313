import React, {useState} from "react";
import {
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography
} from "@mui/material";
import {IPersonHasDocument} from "../../../../../../../Interfaces/IPersonHasDocument";
import {IMortgagePerson} from "../../../../../../../Interfaces/IPerson";
import {IMortgage} from "../../../../../../../Interfaces/IMortgage";
import {MortgageOwnerRow} from "./MortageOwnerRow";
import {getMortgagePerson, MortgagePerson} from "./MortgagePerson";


interface IProps {
    mortgageObject: IMortgage;
    setMortgageObject: Function;
    personArray: IMortgagePerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;

}


const getNextIdPerson = (personArray: IMortgagePerson[]): number => {
    let minId = Math.min(...personArray.map(x => x.idPerson)) - 1;
    if (minId > 0) {
        minId = -1;
    }
    return minId
}

export const MortgageOwnerTable: React.FC<IProps> = (props) => {
    const [isDisabled, setIsDisabled] = useState(false)
    const [isNewOpen, setIsNewOpen] = useState(false);
    const [editNewOwner, setEditNewOwner] = useState<IMortgagePerson>(() => {
        return getMortgagePerson(getNextIdPerson(props.personArray));
    });

    const handleAddNew = () => {
        props.setPersonArray([...props.personArray, editNewOwner]);
        setIsNewOpen(false);
    }

    const addNewOwner = () => {
        setEditNewOwner(getMortgagePerson(getNextIdPerson(props.personArray)));
        setIsNewOpen(true);
    }
    return (
        <>
            <Typography variant="h5">
                Eigentümer
            </Typography>

            <Typography variant="body1" sx={{mt: 2, mb: 3}}>
                Bitte erfassen Sie alle Eigentümer
            </Typography>

            {(isNewOpen) &&
                <Dialog
                    open={isNewOpen}
                    maxWidth="md"
                    fullWidth
                    onClose={() => setIsNewOpen(false)}
                >
                    <DialogContent>
                        <Box sx={{mt: 2}}/>
                        <MortgagePerson
                            currentPerson={editNewOwner}
                            setCurrentPerson={setEditNewOwner}
                            mortgageObject={props.mortgageObject}
                            setMortgageObject={props.setMortgageObject}
                            personHasDocument={props.personHasDocument}
                            setPersonHasDocument={props.setPersonHasDocument}
                            isDisabled={isDisabled} setIsDisabled={setIsDisabled}/>
                    </DialogContent>
                    <DialogActions>
                        <Button variant="outlined" onClick={() => {
                            setIsNewOpen(false)
                        }}>Abbruch</Button>
                        <Button variant="contained" disabled={isDisabled} onClick={() => {
                            handleAddNew()
                        }}>Speichern</Button>
                    </DialogActions>
                </Dialog>

            }

            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nr.</TableCell>
                        <TableCell>Vorname</TableCell>
                        <TableCell>Nachname</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    <MortgageOwnerRow
                        mortgageObject={props.mortgageObject}
                        setMortgageObject={props.setMortgageObject}
                        personArray={props.personArray}
                        setPersonArray={props.setPersonArray}
                        personHasDocument={props.personHasDocument}
                        setPersonHasDocument={props.setPersonHasDocument}
                    />
                </TableBody>
                <br></br>
                <br></br>
                <Button variant="contained" onClick={() => addNewOwner()} sx={{float: "left"}}>Eigentümer
                    Hinzufügen</Button>
                <br></br>
                <br></br>
                <br></br>
            </Table>
        </>
    )
}
//<IconButton onClick={() => setIsNewOpen(true)} sx={{float: "right"}}><Add/></IconButton>