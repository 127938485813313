import {
    Alert,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
    ListItem,
    ListItemButton,
    ListItemText,
    Snackbar,
    TextField,
    Typography,
    useMediaQuery
} from "@mui/material"
import React, {MouseEvent, useEffect, useState} from "react";
import ReactPasswordChecklist from "react-password-checklist";


interface IProps {
    idLegaltransaction: number;
    dataToSave: any;
    filename: String;
}

export const SaveProgress: React.FC<IProps> = (props) => {
    const isDesktop = useMediaQuery('(min-width:900px)');


    const [openExport, setOpenExport] = React.useState(false);
    const [password, setPassword] = useState("");
    const [passwordAgain, setPasswordAgain] = useState("")
    const [passwordWasSuccessfull, setPasswordWasSuccessfull] = React.useState(false);
    const [isShowPasswordEnabled, setisShowPasswordEnabled] = React.useState(false);
    const [wasPasswordGenerated, setwasPasswordGenerated] = React.useState(false);

    const handleOpenExport = () => {
        setOpenExport(true);
    };
    const handleCloseExport = () => {
        setOpenExport(false);
    };

    const clearPassword = () => {
        setPassword("")
        setPasswordAgain("")
        setisShowPasswordEnabled(false)
        setwasPasswordGenerated(false)
    }

    const checkPasswordValidity = (value: string, enablePwCheck: boolean = true) => {
        const nonWhiteSpace = /^\S*$/;
        if (!nonWhiteSpace.test(value)) {
            return false;
        }

        const containsUppercase = /^(?=.*[A-Z]).*$/;
        if (!containsUppercase.test(value)) {
            return false;
        }

        const containsLowercase = /^(?=.*[a-z]).*$/;
        if (!containsLowercase.test(value)) {
            return false;
        }

        const containsNumber = /^(?=.*[0-9]).*$/;
        if (!containsNumber.test(value)) {
            return false;
        }

        const containsSymbol =
            /^(?=.*[~`!@#$%^&*()--+={}\[\]|\\:;"'<>,.?/_₹]).*$/;
        if (!containsSymbol.test(value)) {
            return false;
        }

        const validLength = /^.{12,32}$/;
        if (!validLength.test(value)) {
            return false;
        }
        if (enablePwCheck && password != passwordAgain) {
            return false;
        }
        return true;
    }

    function checkAndGetRandomPassword() {
        let result = generateRandomPassword()
        while (!checkPasswordValidity(result, false)) {
            result = generateRandomPassword()
        }
        setPassword(result)
        setPasswordAgain(result)
        setisShowPasswordEnabled(true)
        setwasPasswordGenerated(true)
    }

    function showHidePassword() {
        setisShowPasswordEnabled(!isShowPasswordEnabled)
    }

    function generateRandomPassword() {
        let result = '';
        const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789!"§$%&/()=?+-*#';
        const charactersLength = characters.length;
        let counter = 0;
        while (counter < 16) {
            result += characters.charAt(Math.floor(Math.random() * charactersLength));
            counter += 1;
        }
        return result
    }

    const downloadJson = () => {
        if (passwordWasSuccessfull) {
            var CryptoJS = require("crypto-js");
            const element = document.createElement("a");
            const textFile = new Blob([CryptoJS.AES.encrypt(JSON.stringify(props.dataToSave), password)], {type: 'text/plain'}); //pass data from localStorage API to blob
            element.href = URL.createObjectURL(textFile);
            element.download = `${props.filename}.notar`;
            document.body.appendChild(element);
            element.click();
        }
    }


    const antiEventBubbling = (event: any) => {
        event.stopPropagation(); // Unterbinde Event Bubbling
    }

    const handleClickOpen = (event: any) => {
        event.stopPropagation(); // Unterbinde Event Bubbling
        clearPassword();
        handleOpenExport();
    }


    return (
        <>
            { (isDesktop)
                ? <Button key="saveProgress-Button" color="inherit" variant="outlined" onClick={handleClickOpen}>Sichern</Button>
                : <ListItem key="saveProgress-Button" disablePadding>
                        <ListItemButton onClick={handleClickOpen}>
                            <ListItemText primary="Sichern" />
                        </ListItemButton>
                </ListItem>
            }

            {/*Informiert den User dass das generierte Passwort manuell gespeichert werden soll*/}
            <Snackbar open={wasPasswordGenerated} autoHideDuration={6000} onClose={() => setwasPasswordGenerated(false)}
                      anchorOrigin={{vertical: "top", horizontal: "center"}}>
                <Alert onClose={() => setwasPasswordGenerated(false)} severity={"info"} sx={{width: '100%'}}>
                    Bitte das generierte Passwort abspeichern
                </Alert>
            </Snackbar>

            <Dialog open={openExport} onClose={handleCloseExport} onClick={antiEventBubbling}>
                <DialogTitle>Exportieren</DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Bitte geben sie hier ein Passwort zum verschlüsseln der Datei ein
                    </DialogContentText>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Passwort"
                        type={(isShowPasswordEnabled) ? "string" : "password"}
                        value={password}
                        fullWidth
                        required
                        variant="standard"
                        onChange={e => {
                            setPassword(e.target.value)
                        }}
                    />
                    <Typography><br></br>Bitte Passwort erneut eingeben</Typography>
                    <TextField
                        autoFocus
                        margin="dense"
                        id="password"
                        label="Passwort"
                        type={(isShowPasswordEnabled) ? "string" : "password"}
                        value={passwordAgain}
                        fullWidth
                        required
                        variant="standard"
                        onChange={e => {
                            setPasswordAgain(e.target.value)
                        }}
                    />
                    <br></br><br></br>
                    <Button onClick={() => {
                        checkAndGetRandomPassword()
                    }}>Passwort generieren</Button>

                    <Button onClick={showHidePassword}>Passwort anzeigen</Button>
                    <ReactPasswordChecklist
                        rules={["minLength", "specialChar", "number", "capital", "match"]}
                        minLength={12}
                        value={password}
                        valueAgain={passwordAgain}
                        onChange={(passwordWasSuccessfull) => setPasswordWasSuccessfull(passwordWasSuccessfull)}
                        messages={{
                            minLength: "Das Passwort hat 12 Zeichen.",
                            specialChar: "Das Passwort hat ein Sonderzeichen.",
                            number: "Das Passwort hat eine Zahl.",
                            capital: "Das Passwort hat ein Großbuchstaben.",
                            match: "Die Passwörter stimmen überein",
                        }}
                    />

                </DialogContent>
                <DialogActions>
                    <Button onClick={() => {
                        handleCloseExport();
                        clearPassword()
                    }}>Abbrechen</Button>
                    <Button disabled={!passwordWasSuccessfull} onClick={() => {
                        handleCloseExport();
                        downloadJson();
                        clearPassword()
                    }}>Speichern</Button>
                </DialogActions>
            </Dialog>


        </>
    )
}