import React, {useEffect, useState} from "react";
import {
    Alert,
    Box, Button,
    Checkbox,
    Collapse,
    FormControlLabel, Grid,
    IconButton,
    MenuItem, Switch,
    TextField,
    Typography
} from "@mui/material";

import {DataGrid, deDE, GridColDef, GridRenderCellParams, GridValueGetterParams} from "@mui/x-data-grid";
import {Delete} from "@mui/icons-material";
import { ICompanyFormation } from "../../../../../Interfaces/ICompanyFormation";
import { IShareHolderFullObject } from "../../../../../Interfaces/IShareHolder";
import { ICompanyFormationHasShareHolder } from "../../../../../Interfaces/ICompanyFormationHasShareHolder";
import { ICompanyFormationAnswer } from "../../../../../Interfaces/ICompanyFormationAnswer";
import { ICompanyFormationQuestion } from "../../../../../Interfaces/ICompanyFormationQuestion";
import { ICompanyFormationOption } from "../../../../../Interfaces/ICompanyFormationOption";
import { getCompanyFormationAnswer } from "../../Founding/companyFormation_helpers";
import { useTranslation } from "react-i18next";
import { checkTranslation } from "../../../../core/standardFunctions";


export const addUpdateAnswerArray = (answerArray: ICompanyFormationAnswer[], currentObject: ICompanyFormationAnswer) => {
    let testObject = answerArray.find(x => x.idCompanyFormationQuestion === currentObject.idCompanyFormationQuestion);

    if (testObject === undefined) {
        return [
            ...answerArray,
            currentObject,
        ]
    } else {
        return [
            ...answerArray
                .map(x => x.idCompanyFormationQuestion === currentObject.idCompanyFormationQuestion ? currentObject : x)
        ]
    }
}


interface IProps {
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
    setTxt: Function;
    currentStep: number;
    handleBack: Function;
    handleNext: Function;
    shareHolderArray: IShareHolderFullObject[];
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    setShareHolderRelation: Function;
    setShareHolderArray: Function;
    companyFormationAnswerArray: ICompanyFormationAnswer[];
    setCompanyFormationAnswerArray: Function;
    companyFormationQuestionArray: ICompanyFormationQuestion[];
    companyFormationOptionArray: ICompanyFormationOption[];

    //Für CompanyChange Buttons deaktiveren
    buttonsDisabled?: boolean;
}


export const RARepresentative: React.FC<IProps> = (props) => {
    const [currentVertreterregelungsform, setCurrentVertreterregelungsform] = useState(-1)

    //const [currentEintragungsart, setCurrentEintragungsart] = useState(-1)

    const { t } = useTranslation();

    const [companyRepresentationAnswer, setCompanyRepresentationAnswer] = useState(getCompanyFormationAnswer(7, props.companyFormationAnswerArray));
    const [company181Answer, setCompany181Answer] = useState(getCompanyFormationAnswer(8, props.companyFormationAnswerArray, 29));
    const [resetCounter, setResetCounter] = useState(0);


    useEffect(() => {
        props.setCompanyFormationAnswerArray(
            addUpdateAnswerArray(props.companyFormationAnswerArray, companyRepresentationAnswer)
        )
    }, [companyRepresentationAnswer])

    useEffect(() => {
        props.setCompanyFormationAnswerArray(
            addUpdateAnswerArray(props.companyFormationAnswerArray, company181Answer)
        )
    }, [company181Answer])


    const getCompanyFormationOptionDescription = (idCompanyFormationQuestion: number, idCompanyFormationOption: number) => {
        let companyformationquestionObject = props.companyFormationOptionArray.find(x => x.idCompanyFormationOption === idCompanyFormationOption);

        if (companyformationquestionObject === undefined) {
            return <>Fehler</>
        } else {
            return String(companyformationquestionObject.Description)
        }
    }


    return (
        <>
            {/*JSON.stringify(props.companyFormationAnswerArray)*/}
            <Grid container spacing={2}>
                <Grid item sm={12}>
                    <Typography variant="h6">{checkTranslation(t,"representation_regulation","Allgemeine Vertretungsregelung")}</Typography>
                </Grid>
                <Grid item sm={12}>
                    <Alert severity="info">
                    {checkTranslation(t,"representation_regulation_text","Bitte geben Sie die im Text der Satzung enthaltene Vertretungsregelung an. Sie können hierzu - sofern übereinstimmend - eine der in der nachfolgenden Drop-down-Liste aufgeführten Musterregelungen verwenden. Andernfalls hinterlegen Sie bitte die individuelle Regelung Ihrer Vereinssatzung.")}
                    </Alert>
                </Grid>
                <Grid item sm={12}>
                    <TextField
                        label={checkTranslation(t,"representation_regulation","Allgemeine Vertretungsregelung")}
                        size="small"
                        type="number"
                        fullWidth
                        select
                        value={(companyRepresentationAnswer.idCompanyFormationOption < 0) ? "" : companyRepresentationAnswer.idCompanyFormationOption}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                            // Setzen des Values
                            //setCurrentVertreterregelungsform(Number(event.target.value));
                            setCompanyRepresentationAnswer({
                                ...companyRepresentationAnswer,
                                idCompanyFormationOption: Number(event.target.value)
                            })
                        }}
                    >
                        {props.companyFormationOptionArray.filter(y => y.idCompanyFormationQuestion === 7).map(x =>
                            <MenuItem
                                title={String(x.Description)}
                                key={`VertreterregelungsformRepresentative-${x.idCompanyFormationOption}`}
                                value={x.idCompanyFormationOption}
                            >
                                {x.CompanyFormationOption}
                            </MenuItem>
                        )}
                    </TextField>
                </Grid>
            </Grid>


            <Box sx={{mt: 2}}/>
            <Collapse in={companyRepresentationAnswer.idCompanyFormationOption === 23}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField
                            label={checkTranslation(t,"specific_representation_regulation","Ihre Vertretungsregelung")}
                            fullWidth
                            size="small"
                            error={companyRepresentationAnswer.TextValue === null}
                            value={companyRepresentationAnswer.TextValue === null ? "" : companyRepresentationAnswer.TextValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCompanyRepresentationAnswer({
                                    ...companyRepresentationAnswer,
                                    TextValue: (event.target.value === "") ? null : event.target.value
                                })
                            }}
                            multiline
                            rows={3}
                        />
                    </Grid>
                </Grid>
            </Collapse>

            <Box sx={{mt: 5}}/>
            <Collapse in={companyRepresentationAnswer.idCompanyFormationOption > 0}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <Typography variant="h6">{checkTranslation(t,"regulation_§181_BGB","Regelung zu § 181 BGB")}</Typography>
                    </Grid>
                    <Grid item sm={12}>
                        <Alert severity="info">
                        {checkTranslation(t,"regulation_181_bgb_text","In der Regel enthalten Vereinssatzungen keine Befreiung von § 181 BGB, d.h. Vorstandsmitglieder dürfen i.d.R. keine Verträge mit sich selbst oder zugleich als Vertreter eines Dritten abschließen. Sollte dies bei Ihrem Verein anders sein, wählen Sie bitte eine der in der nachfolgenden Drop-down-Liste enthaltenen Regelungen aus.")}
                        </Alert>
                    </Grid>
                    <Grid item sm={12}>
                        <TextField
                            label={checkTranslation(t,"regulation","Regelung")}
                            size="small"
                            type="number"
                            fullWidth
                            select
                            value={company181Answer.idCompanyFormationOption}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                // Setzen des Values
                                //setCurrentVertreterregelungsform(Number(event.target.value));
                                setCompany181Answer({
                                    ...company181Answer,
                                    idCompanyFormationOption: Number(event.target.value)
                                })
                            }}
                        >
                            {props.companyFormationOptionArray.filter(y => y.idCompanyFormationQuestion === 8).map(x =>
                                <MenuItem
                                    title={String(x.Description)}
                                    key={`companyHasPropertyAnswer-${x.idCompanyFormationOption}`}
                                    value={x.idCompanyFormationOption}
                                >
                                    {x.CompanyFormationOption}
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid>
                </Grid>
            </Collapse>
            <Box sx={{mt: 2}}/>
            <Collapse in={company181Answer.idCompanyFormationOption === 27}>
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                    {checkTranslation(t,"specific_regulation_definition","Bitte definieren Sie Ihre eigene Regelung.")}
                    </Grid>

                    <Grid item sm={12}>
                        <TextField
                            label={checkTranslation(t,"specific_regulation","Ihre Regelung")}
                            fullWidth
                            size="small"
                            error={company181Answer.TextValue === null}
                            value={company181Answer.TextValue === null ? "" : company181Answer.TextValue}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                setCompany181Answer({
                                    ...company181Answer,
                                    TextValue: (event.target.value === "") ? null : event.target.value
                                })
                            }}
                            multiline
                            rows={3}
                        />
                    </Grid>
                </Grid>
            </Collapse>





            {(props.buttonsDisabled === undefined || props.buttonsDisabled === false) &&
                <Box sx={{ mt: 5, display: 'flex', flexDirection: 'row', pt: 2 }}>
                    <Button
                        variant="outlined"
                        onClick={() => props.handleBack()}
                        sx={{ mr: 1 }}
                    >
                        {checkTranslation(t, "button_ra_back", "Zurück")}
                    </Button>
                    <Box sx={{ flex: '1 1 auto' }} />
                    <Button
                        onClick={() => props.handleNext()}
                        disabled={
                            companyRepresentationAnswer.idCompanyFormationOption <= 0
                            || (companyRepresentationAnswer.idCompanyFormationOption === 2 && companyRepresentationAnswer.NumberValue === null)
                            || (companyRepresentationAnswer.idCompanyFormationOption === 16 && companyRepresentationAnswer.TextValue === null )
                            || (company181Answer.idCompanyFormationOption === 14 && company181Answer.TextValue === null)
                        }
                        type="submit"  
                        variant="contained"
                    >
                        {checkTranslation(t, "button_next", "Weiter")}
                    </Button>
                </Box>
            }

        </>
    )
}
