import { Box, Button, Typography } from "@mui/material";
import React from "react";


interface IProps {
    currentStep: number;
    setcurrentStep: Function;
    handleNext: Function;
}


export const Start:React.FC<IProps> = (props) => {
    return(
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4" sx={{ mb: 3 }}>Erbscheinsantrag</Typography>

            <Typography>
                Herzlich willkommen!
                <br/>Mit unserem Dialog können Sie einfach und vollständig digital Ihren Erbscheinsantrag vorbereiten.
                <p />
                Brauchen Sie zwischendurch eine Pause? Sie können Ihre Eingaben jederzeit über das Feld <i>Sichern</i> exportieren und speichern. 
                Setzen Sie Ihre Eingaben dann über die Klickfläche <i>Wiederherstellung</i> fort. 
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    disabled={props.currentStep === 0}
                    onClick={() => {}}
                    sx={{ mr: 1 }}
                >
                    Zurück
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => props.handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>
        </>
    )
}