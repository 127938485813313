import {ICompanyFormationShare} from "../../../../Interfaces/ICompanyFormationShare";
import {ICompanyFormation, ICompanyFormationFullObject} from "../../../../Interfaces/ICompanyFormation";
import {IShareHolderFullObject} from "../../../../Interfaces/IShareHolder";
import {ICompanyFormationHasShareHolder} from "../../../../Interfaces/ICompanyFormationHasShareHolder";
import {ICompanyFormationAnswer} from "../../../../Interfaces/ICompanyFormationAnswer";
import {ICompanyFormationQuestion} from "../../../../Interfaces/ICompanyFormationQuestion";
import {ICompanyFormationOption} from "../../../../Interfaces/ICompanyFormationOption";
import {IDocument} from "../../../../Interfaces/IDocument";
import React, {useEffect, useState} from "react";
import {IPostcode} from "../../../../Interfaces/IPostcode";
import {IContact, IContactFullObject} from "../../../../Interfaces/IContact";
import {ICompany} from "../../../../Interfaces/ICompany";
import {ILegalTransactionFullObject} from "../../../../Interfaces/ILegalTransaction";
import {
    Alert,
    Box,
    CircularProgress,
    Dialog,
    DialogContent,
    DialogTitle,
    Grid, IconButton,
    Table, TableBody, TableCell,
    TableHead, TableRow,
    Typography
} from "@mui/material";
import {ShareHolderEdit} from "../Founding/Childs/ShareHolder/ShareHolderEdit";
import {checkString} from "../../../core/standardFunctions";
import {CompanyFormationAnswerSummary} from "../Founding/Childs/Summary/CompanyFormationAnswerSummary";
import {academicTitleArray} from "../../../../StaticArraies/academicTitleArray";
import {genderArray} from "../../../../StaticArraies/genderArray";
import {Search} from "@mui/icons-material";
import {DocumentOverview} from "../../../Document/DocumentOverview";
import {ClientInformation} from "../../../generic/ClientInformation";
//import {SummaryCompanyData} from "../CompanyChange/SummaryComponentns/SummaryCompanyData";
import {RAChangeSummaryComparison} from "./RAChangeSummaryComparison";
import { config } from "process";
import { IConfig } from "../../../../Interfaces/IConfig";


export interface IProps {
    configObject: IConfig;
    handleBack: Function;
    handleNext: Function;
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
    shareHolderArray: IShareHolderFullObject[];
    setShareHolderArray: Function;
    companyFormationShareArray: ICompanyFormationShare[];
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    companyFormationAnswerArray: ICompanyFormationAnswer[];
    companyFormationQuestionArray: ICompanyFormationQuestion[];
    companyFormationOptionArray: ICompanyFormationOption[];
    documentArray: IDocument[];
    setDocumentArray: Function;


    //Vor der Änderung
    shareHolderArrayOld: IShareHolderFullObject[]
    companyFormationShareArrayOld: ICompanyFormationShare[]
    shareHolderRelationOld: ICompanyFormationHasShareHolder[]
    companyFormationAnswerArrayOld: ICompanyFormationAnswer[]
    companyFormationOptionArrayOld: ICompanyFormationOption[]
    companyFormationQuestionArrayOld: ICompanyFormationQuestion[]
    companyFormationObjectOld: ICompanyFormation
}


export const RAChangeSummary: React.FC<IProps> = (props) => {
    const [idShareHolder, setIdShareHolder] = useState<number | null>(null);
    //
    const [postCodeObject, setPostCodeObject] = useState<IPostcode | undefined>(undefined);
    const [addressPostCodeObject, setAddressPostCodeObject] = useState<IPostcode | undefined>(undefined);

    const [personObject, setPersonObject] = useState<IContact>({
        idContact: -1,
        idCompany: -1,
        idGender: 1,
        idAcademicTitle: 1,
        FirstName: "",
        LastName: "",
        Email: ""
    } as IContact);

    const [companyObject, setCompanyObject] = useState<ICompany>({
        idCompany: -1,
        Company: "",
        idBusinessForm: 1,
        idPostcode: null,
        Street: "",
        StreetNr: "",
        StreetAditional: "",
        PhoneNumber: "",
        Website: "",
        RegisterCourt: null,
        RegisterNumber: null,
        TaxNumber: null,
        SalesTaxNumber: null
    })

    //
    const [isLoading, setIsLoading] = useState(false);
    const [wasSuccessfully, setWasSuccessfully] = useState(true);


    useEffect(() => {
        if (props.companyFormationObject.idPostcode > 0) {
            fetch(`/api/postcode/${props.companyFormationObject.idPostcode}`, {
                headers: {
                    "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        throw Error(`ERROR GET: ${res.status}`)
                    }
                })
                .then(res => {
                    setPostCodeObject(res);
                })
                .catch((error) => {
                    console.error(error);
                })
        }
    }, [props.companyFormationObject.idPostcode])


    useEffect(() => {
        if (props.companyFormationObject.Postcode_AddressidPostcode !== null && props.companyFormationObject.Postcode_AddressidPostcode > 0) {
            fetch(`/api/postcode/${props.companyFormationObject.Postcode_AddressidPostcode}`, {
                headers: {
                    "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
                }
            })
                .then(res => {
                    if (res.status === 200) {
                        return res.json()
                    } else {
                        throw Error(`ERROR GET: ${res.status}`)
                    }
                })
                .then(res => {
                    setAddressPostCodeObject(res);
                })
                .catch((error) => {
                    console.error(error);
                })
        } else {
            setAddressPostCodeObject(undefined);
        }
    }, [props.companyFormationObject.Postcode_AddressidPostcode])


    async function handleSubmit() {
        setIsLoading(true);
        //handleNext();
        let legalTransactionFullObject = {
            idLegalTransaction: -1,
            idLegalTransactionState: 1,
            idLegalTransactionType: 6,
            idContact: -1,
            ContactObject: {
                ...personObject,
                CompanyObject: companyObject
            } as IContactFullObject,
            CompanyFormation: {
                ...props.companyFormationObject,
                ShareHolderArray: props.shareHolderArray,
                CompanyFormationShareArray: props.companyFormationShareArray,
                CompanyFormationHasShareHolderArray: props.shareHolderRelation,
                CompanyFormationAnswerArray: props.companyFormationAnswerArray,
                DocumentArray: props.documentArray
            } as ICompanyFormationFullObject
        } as ILegalTransactionFullObject
        console.log(legalTransactionFullObject)
        fetch("/api/legaltransaction/createCompanyFomration", {
            method: "POST",
            headers: {
                "X-APIKEY-LOCAL": "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
                "Content-type": "application/json"
            },
            body: JSON.stringify(legalTransactionFullObject)
        })
            .then(res => {
                if (res.status === 200) {
                    return res.json()
                } else {
                    throw Error(`ERROR GET: ${res.status}`)
                }
            })
            .then(res => {
                setIsLoading(false);
                props.handleNext();
            })
            .catch((error) => {
                console.error(error);
                setWasSuccessfully(false);
            })
    }


    return (
        <>
            <Dialog
                open={isLoading}
            >
                <DialogTitle>Bitte warten</DialogTitle>
                <DialogContent>
                    Ihre Anfrage wird derzeitig hochgeladen.

                    <Grid
                        container
                        sx={{mt: 3}}
                        spacing={0}
                        direction="column"
                        alignItems="center"
                        justifyContent="center"
                    >
                        <Grid item xs={3}>
                            {(wasSuccessfully)
                                ? <CircularProgress/>
                                : <Alert severity="error">Es ist ein Fehler aufgetretten!</Alert>
                            }

                        </Grid>
                    </Grid>

                </DialogContent>
            </Dialog>


            <Dialog open={idShareHolder !== null} onClose={() => setIdShareHolder(null)} maxWidth="md" fullWidth>
                <DialogTitle>{(idShareHolder === null || idShareHolder === 0) ? <>Neue Person</> : <>Person
                    bearbeiten</>}</DialogTitle>
                <DialogContent>
                    <Box sx={{mt: 2}}>
                        {(idShareHolder !== null) &&
                            <ShareHolderEdit
                                idShareHolder={idShareHolder}
                                handleClose={() => setIdShareHolder(null)}
                                shareHolderArray={props.shareHolderArray}
                                setShareHolderArray={props.setShareHolderArray}
                                disableSelectCompanyType={
                                    props.shareHolderArray
                                        .find(x => x.idShareHolder === idShareHolder)
                                        ?.idBusinessForm === 1
                                }
                            />
                        }
                    </Box>
                </DialogContent>
            </Dialog>


            <Box sx={{mt: 5}}/>
            <Typography variant="h4" sx={{mb: 3}}>Abschluss</Typography>

            <Box sx={{mt: 5}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                Zusammenfassung
            </Typography>

            <Grid container spacing={10}>
                <Grid item sm={6}>
                    <Typography><u><b>Bisherige Informationen</b></u></Typography>
                    <Box sx={{pt: "15px"}}></Box>

                    <RAChangeSummaryComparison
                        companyFormationObject={props.companyFormationObjectOld}
                        shareHolderArray={props.shareHolderArrayOld}
                        companyFormationShareArray={props.companyFormationShareArrayOld}
                        shareHolderRelation={props.shareHolderRelationOld}
                        companyFormationAnswerArray={props.companyFormationAnswerArrayOld}
                        companyFormationQuestionArray={props.companyFormationQuestionArrayOld}
                        companyFormationOptionArray={props.companyFormationOptionArrayOld}
                        postCodeObject={postCodeObject}
                        addressPostCodeObject={addressPostCodeObject}
                        setIdShareHolder={setIdShareHolder}/>


                </Grid>
                <Grid item sm={6}>
                    <Typography><u><b>Neue Informationen</b></u></Typography>
                    <Box sx={{pt: "15px"}}></Box>
                    <RAChangeSummaryComparison
                        companyFormationObject={props.companyFormationObject}
                        shareHolderArray={props.shareHolderArray}
                        companyFormationShareArray={props.companyFormationShareArray}
                        shareHolderRelation={props.shareHolderRelation}
                        companyFormationAnswerArray={props.companyFormationAnswerArray}
                        companyFormationQuestionArray={props.companyFormationQuestionArray}
                        companyFormationOptionArray={props.companyFormationOptionArray}
                        documentArray={props.documentArray}
                        postCodeObject={postCodeObject}
                        addressPostCodeObject={addressPostCodeObject}
                        setIdShareHolder={setIdShareHolder}/>
                </Grid>
            </Grid>


            <Box sx={{mt: 10}}/>
            <Typography variant="h6" sx={{mb: 3}}>
                Dokumente
            </Typography>
            <DocumentOverview
                allowRemove
                allowUpload
                documentArray={props.documentArray}
                setDocumentArray={props.setDocumentArray}
                title=""
            />

            <ClientInformation
                configObject={props.configObject}
                disableHasAccount={props.configObject.Module.find(x => x === "company_formation") === undefined}
                contactFullObject={{...personObject, CompanyObject: companyObject} as IContactFullObject}
                setContactFullObject={(localFullObject: IContactFullObject) => {
                    setPersonObject(localFullObject);
                    setCompanyObject(localFullObject.CompanyObject)
                }}
                handleBack={() => props.handleBack()}
                handleSave={handleSubmit}
                isTranslationEnabled={false}/>
        </>
    )
}