import React from "react";
import { Grid, MenuItem, TextField } from "@mui/material";
import { useTranslation } from "react-i18next";
import { IMortgagePerson } from "../../../../../../../Interfaces/IPerson";
import { genderArray } from "../../../../../../../StaticArraies/genderArray";
import { academicTitleArray } from "../../../../../../../StaticArraies/academicTitleArray";
import { staticCountryArray } from "../../../../../../../StaticArraies/staticCountryArray";



interface IProps {
  beneficiaryObject: IMortgagePerson;
  setBeneficiaryObject: Function;
}


export const PersonData:React.FC<IProps> = (props)  => {
    const { t } = useTranslation();

    return(
        <Grid container spacing={2}>
            <Grid item xs={6} sm={2}>
                <TextField
                    label={"Geschlecht"}
                    size="small"
                    fullWidth
                    required
                    select
                    value={(props.beneficiaryObject.idGender < 0 ) ? 4 : props.beneficiaryObject.idGender}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, idGender: Number(event.target.value)})}
                >
                    {genderArray.map(x =>
                        <MenuItem key={`idGender-${x.idGender}`} value={x.idGender}>{x.Gender}</MenuItem>
                    )}
                    required
                </TextField>
            </Grid>
            <Grid item xs={6} sm={2}>
                <TextField
                    label="Titel"
                    size="small"
                    fullWidth
                    select
                    value={props.beneficiaryObject.idAcademicTitle}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, idAcademicTitle: Number(event.target.value)})}
                >
                    {academicTitleArray.map(x =>
                        <MenuItem key={`idAcademicTitle-${x.idAcademicTitle}`} value={x.idAcademicTitle}>{x.AcademicTitle}</MenuItem>
                    )}
                </TextField>
            </Grid>
            <Grid item xs={6} sm={4}>
                <TextField
                    label="Vorname"
                    size="small"
                    fullWidth
                    value={props.beneficiaryObject.FirstName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, FirstName: event.target.value})}
                    required
                />
            </Grid>
            <Grid item xs={6} sm={4}>
                <TextField
                    label="Nachname"
                    size="small"
                    fullWidth
                    value={props.beneficiaryObject.LastName}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, LastName: event.target.value})}
                    required
                />
            </Grid>


            <Grid item sm={6}>
                <TextField
                    label="Nationalität"
                    size="small"
                    fullWidth
                    select
                    value={props.beneficiaryObject.idCountry === null ?  1 : props.beneficiaryObject.idCountry}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, idCountry: Number(event.target.value)})}
                >
                    {staticCountryArray.map(x =>
                        <MenuItem key={`idCountry-${x.idCountry}`} value={x.idCountry}>{x.Nationality}</MenuItem>
                    )}
                </TextField>
            </Grid>
            <Grid item sm={6}>
                <TextField
                    label="Geburtsname"
                    size="small"
                    fullWidth
                    value={(props.beneficiaryObject.Surname === null) ? "" : props.beneficiaryObject.Surname}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, Surname: (event.target.value === "") ? null : event.target.value})}
                />
            </Grid>
        </Grid>
    )
}