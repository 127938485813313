import { Help } from "@mui/icons-material";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, Drawer, Grid, IconButton, MenuItem, TextField, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import PostcodeSearch from "../../../../../../generic/PostcodeSearch";
import { ICompanyFormation } from "../../../../../../../Interfaces/ICompanyFormation";
import { IPostcode } from "../../../../../../../Interfaces/IPostcode";
import { IMortgagePerson } from "../../../../../../../Interfaces/IPerson";


interface IProps {
    beneficiaryObject: IMortgagePerson;
    setBeneficiaryObject: Function;
    isImportedFromHandelsregister? : boolean;
}

export const handlePostcode = (beneficiaryObject: IMortgagePerson) => {
    if (beneficiaryObject.idPostcode != null 
        && beneficiaryObject.idPostcode > 0
        && beneficiaryObject.City != undefined
        && beneficiaryObject.Postcode != undefined
    ) {
        return {
            idPostcode: beneficiaryObject.idPostcode,
            City: beneficiaryObject.City,
            Postcode: beneficiaryObject.Postcode,
            idCountry: 1
        } as IPostcode
    } else {
        return null;
    }
}



const CompanyModifiedBaseInformation:React.FC<IProps> = (props) => {
    // 
    const [postcodeObject, setPostcodeObject] = useState<IPostcode | null | undefined>(handlePostcode(props.beneficiaryObject));

    const setPostcodeObjectWrapper = (postcodeObject:IPostcode | null | undefined) => {
        if (postcodeObject != undefined) {
            props.setBeneficiaryObject({
                ...props.beneficiaryObject,
                idPostcode: postcodeObject.idPostcode,
                Postcode: postcodeObject.Postcode,
                City: postcodeObject.City,
            } as IMortgagePerson)

        }
        setPostcodeObject(postcodeObject);
    }

    return(
        <>
            <Box sx={{mt: 5}} />
            <Typography variant="h6" sx={{mb: 3}}>
                Firmendaten
            </Typography>
            <Grid container spacing={2}>
                <Grid item sm={8}>
                    <TextField
                        label="Firmenname"
                        size="small"
                        fullWidth
                        value={props.beneficiaryObject.Company}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, Company: event.target.value})}
                        required
                    />
                </Grid>
            </Grid>
            <Box sx={{mt: 5}} />
            <Typography variant="h6" sx={{mb: 3}}>Firmenanschrift</Typography>
            <Grid container spacing={2}>
                <Grid item sm={8}>
                    <TextField
                        label="Straße"
                        size="small"
                        fullWidth
                        value={props.beneficiaryObject.Street}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, Street: event.target.value})}
                        required
                    />
                </Grid>
                <Grid item sm={4}>
                    <TextField
                        label="Nr"
                        size="small"
                        fullWidth
                        value={props.beneficiaryObject.StreetNr}
                        onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setBeneficiaryObject({...props.beneficiaryObject, StreetNr: event.target.value})}
                        required
                    />
                </Grid>
                <Grid item sm={12}>
                    <PostcodeSearch 
                        postcodeObject={postcodeObject} 
                        setPostcodeObject={setPostcodeObjectWrapper}
                    />
                </Grid>
            </Grid>
    </>
    )
}
export default CompanyModifiedBaseInformation;