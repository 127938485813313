import React, { useState } from "react";
import { IInherit } from "../../../../../../../Interfaces/IInherit";
import { IInheritHasPerson } from "../../../../../../../Interfaces/IPerson";
import { Button, Table, TableBody, TableCell, TableHead, TableRow } from "@mui/material";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { StrangersRow } from "./StrangersRow";
import { getInheritPerson } from "../InheritPerson";
import { useTranslation } from "react-i18next";
import {checkTranslation} from "../../../../../../core/standardFunctions";



interface IProps {
    inheritObject: IInherit;
    personArray: IInheritHasPerson[];
    setPersoArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
}


export const StrangersTable:React.FC<IProps> = (props) => {
    const { t } = useTranslation();
    const [newPerson, setNewPerson] = useState(getInheritPerson(-1,props.personArray,true));
    const [isNewOpen,setIsNewOpen] = useState(false);
    
    const handleOpenNew = () => {
        setNewPerson(getInheritPerson(-1,props.personArray,true));
        setIsNewOpen(true);
    }



    return(
        <>
            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>{checkTranslation(t,"number","Nr.")}</TableCell>
                        <TableCell>{checkTranslation(t,"first_name","Vorname")}</TableCell>
                        <TableCell>{checkTranslation(t,"last_name", "Nachname")}</TableCell>
                        <TableCell>{checkTranslation(t,"birth_date", "Geburtstag")}</TableCell>
                        <TableCell>{checkTranslation(t,"actions", "Aktionen")}</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {(isNewOpen) &&
                        <StrangersRow
                            key={`stranger-new`}
                            index={0}
                            currentPerson={newPerson}
                            inheritObject={props.inheritObject}
                            personArray={props.personArray}
                            setPersoArray={props.setPersoArray}
                            personHasDocument={props.personHasDocument}
                            setPersonHasDocument={props.setPersonHasDocument}
                            setIsOpenNew={setIsNewOpen}
                        />
                    }

                    {props.personArray.filter(x => x.isStranger).map((x,i) =>
                        <StrangersRow
                            key={`stranger-${x.idPerson}-${i}`}
                            index={i}
                            currentPerson={x}
                            inheritObject={props.inheritObject}
                            personArray={props.personArray}
                            setPersoArray={props.setPersoArray}
                            personHasDocument={props.personHasDocument}
                            setPersonHasDocument={props.setPersonHasDocument}
                        />
                    )}
                </TableBody>
            </Table>
            <br></br>
            <Button variant="contained" onClick={handleOpenNew} sx={{float: "left"}}>{checkTranslation(t,"add_person", "Person hinzufügen")}</Button>


        </>
    )
}