import { Box, Button, Typography } from "@mui/material";
import React from "react";


interface IProps {
    currentStep: number;
    setcurrentStep: Function;
    handleNext: Function;
}


export const Start:React.FC<IProps> = (props) => {
    return(
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4" sx={{ mb: 3 }}>Grundschuld (Bestellung oder Löschung)</Typography>

            <Typography>
                Herzlich Willkommen auf unserer Webseite! Wir freuen uns sehr, dass Sie den Weg zu uns gefunden haben. Bei uns stehen Sie und Ihr Anliegen im Mittelpunkt. Unser Ziel ist es, Ihnen den Prozess der Grundschuldbestellung oder -löschung so einfach und unkompliziert wie möglich zu gestalten.
                <p />
                Eine Grundschuldbestellung oder -löschung kann eine herausfordernde Angelegenheit sein, und wir sind hier, um Ihnen in jeder Phase des Verfahrens zur Seite zu stehen. Unser benutzerfreundlicher Dialog ermöglicht es Ihnen, Schritt für Schritt alle notwendigen Informationen bereitzustellen, damit wir Ihnen bestmöglich helfen können. Wir verstehen, dass es in dieser schwierigen Zeit wichtig ist, dass Sie sich auf kompetente Unterstützung verlassen können.
                <p />
                Unsere Experten stehen Ihnen gerne zur Verfügung, um Ihre Fragen zu beantworten und Sie bei der Antragstellung zu begleiten. Wir sind stolz darauf, Ihnen einen schnellen und effizienten Service bieten zu können, damit Sie sich auf die wichtigen Dinge konzentrieren können.
                <p />
                Zögern Sie nicht, uns zu kontaktieren, wenn Sie weitere Informationen benötigen oder Unterstützung bei Ihrer Grundschuldbestellung oder -löschung wünschen. Wir sind hier, um Ihnen zu helfen.
                <p />
                <i>*Sie können ihren Fortschitt über "Sichern" exportieren und jederzeit nahtlos weitermachen</i>
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    disabled={props.currentStep === 0}
                    onClick={() => {}}
                    sx={{ mr: 1 }}
                >
                    Zurück
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => props.handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>
        </>
    )
}