import React, { useState } from "react";
import { Alert, Box, Button, Collapse, Dialog, DialogActions, DialogContent, Grid, Typography } from "@mui/material";
import { IInheritHasPerson } from "../../../../../../Interfaces/IPerson";
import { GridBaseDate } from "../../../../../Person/GridBaseData";
import { IRequiredFiles, InheritDocument } from "./InheritDocument";
import { IPersonHasDocument } from "../../../../../../Interfaces/IPersonHasDocument";
import { IInherit } from "../../../../../../Interfaces/IInherit";



interface IProps {
    title: string;
    currentPerson: IInheritHasPerson;
    setCurrentPerson: Function;

    inheritObject: IInherit;
    setInheritObject?: Function;

    requiredFiles: IRequiredFiles[];

    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;

    titleAdress?: string;
    showDeathSettings?: boolean;
    forceDeathSettings?: boolean;
    setIsDeath?: Function;
    disableEdit?: boolean;
    personArray: IInheritHasPerson[];

    forceAdress?: boolean;
    disableDeathDateWarning?: boolean;
}


export const getInheritPerson = (idPerson:number, personArray:IInheritHasPerson[],isNew?:boolean) => {
    let testObject = personArray.find(x => x.idPerson === idPerson);
    if ((isNew === undefined ||  isNew === false) && testObject !== undefined) {
        return testObject;
    } else {
        let minId = Math.min(...personArray.map(x => x.idPerson)) -1;
        if (minId >= -1) {
            minId = -2;
        }

        return {
            idPerson: minId,
            Birthday: "",
            Birthplace: "",
            DateOfDeath: null,
            FirstName: "",
            idAcademicTitle: 1,
            idCountry: 1,
            idGender: -1,
            idInherit: -1,
            idPostcode: null,
            LastName: "",
            PlaceOfDeath: null,
            Street: "",
            Relation_idPerson: null,
            StreetAditional: "",
            StreetNr: "",
            Surname: null,
            idBusinessForm: 1,
            isInherit: false,
            isStranger: false,

            Email: null,
            PhoneNumber: null,

            TaxNumber: null,
            IBAN: null,
            BIC: null,
            Bank: null,
        } as IInheritHasPerson
    }
}




export const InheritPerson:React.FC<IProps> = (props) => {
    const [isOpenWarning, setIsOpenWarning] = useState(false);
    const [isMinimalRequirementActiv,setIsMinimalRequirementActiv] = useState(false);

    return(
        <>
            {(false && props.inheritObject.idPerson !== props.currentPerson.idPerson) &&
                <Dialog
                    open={isOpenWarning}
                    onClose={() => setIsOpenWarning(false)}
                >
                    <DialogContent>
                        <Alert severity="warning">
                            Sobald Sie diese Option aktiveren, sind nur noch die Felder <i>Geschlecht</i>, <i>Vorname</i> und <i>Nachname</i> pflicht.
                            <br/>Wir weisen dringlichst darauf hin, dass Sie diese Option nur im Sonderfall aktivieren sollten, 
                            um eine schnelle und reibungslose Abwicklung zu gewährleisetn.
                        </Alert>
                        <Box sx={{mt: 2}}/>
                        Sollen die Pflichtfelder aktiviert oder deaktiviert werden?
                    </DialogContent>

                    <DialogActions>
                        <Button variant="outlined" onClick={() => {setIsMinimalRequirementActiv(true);setIsOpenWarning(false)}}>Deaktivieren</Button>
                        <Button variant="contained" onClick={() => {setIsMinimalRequirementActiv(false);setIsOpenWarning(false)}}>Aktivieren</Button>
                    </DialogActions>
                </Dialog>
            }




            <Typography variant="h5">
                {props.title}
                {/*<Button onClick={() => setIsOpenWarning(true)}  variant="contained" sx={{float: "right"}}>Pflichtfelder {(isMinimalRequirementActiv) ? <>aktivieren</> : <>deaktivieren</>}</Button>*/}
            </Typography>

            <GridBaseDate
                personObject={props.currentPerson}
                personArray={props.personArray}
                setPersonObject={props.setCurrentPerson}
                showDeathSettings={props.showDeathSettings}
                forceDeathSettings={props.forceDeathSettings}
                titleAdress={props.titleAdress}
                setIsDeath={props.setIsDeath}
                disableEdit={props.disableEdit}
                forceAdress={props.forceAdress}
                disableDeathDateWarning={props.disableDeathDateWarning}
                minimalRequiremend={props.inheritObject.idPerson !== props.currentPerson.idPerson}
                disableContactInformations
            />

            {( props.setInheritObject !== undefined) &&
                <Grid container spacing={2}>
                    <Grid item xs={12}>
                        <Typography variant="h5" sx={{mt: 10}}>Testament bzw. Erbvertrag</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        Liegt ein Testament oder ein Erbvertrag vor?
                    </Grid>

                    <Grid item xs={12}>
                        <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                            <Button
                                variant={(props.inheritObject.hasTestament) ? "outlined" : "contained"}
                                onClick={() => props.setInheritObject!({...props.inheritObject, hasTestament: false})}
                                sx={{ mr: 1 }}
                            >
                                Nein
                            </Button>

                            <Box sx={{ flex: '1 1 auto' }} />
                            <Button 
                                variant={(props.inheritObject.hasTestament) ? "contained" : "outlined"}
                                onClick={() => props.setInheritObject!({...props.inheritObject, hasTestament: true})!}
                            >
                                Ja
                            </Button>
                        </Box>
                    </Grid>

                    <Grid item xs={12}>
                        <Collapse in={props.inheritObject.hasTestament}>
                            <InheritDocument
                                key={`InheritDocument-testament`}
                                idPerson={props.currentPerson.idPerson}
                                requiredFile={{ idPersonDocumentType: 5, isOptional: false, Title: "Testament" } as IRequiredFiles}
                                personHasDocument={props.personHasDocument}
                                setPersonHasDocument={props.setPersonHasDocument}
                                disableEdit={props.disableEdit}
                            />
                        </Collapse>
                    </Grid>
                </Grid>
            }

            <Grid container spacing={2}>
            {(props.requiredFiles.length > 0) &&
                <>
                    <Grid item xs={12}><Typography variant="h5" sx={{mt: 10}}>Benötigte Dokumente</Typography></Grid>
                    {props.requiredFiles.map((x,i) =>
                        <InheritDocument
                            key={`InheritDocument-${i}`}
                            idPerson={props.currentPerson.idPerson}
                            requiredFile={x}
                            personHasDocument={props.personHasDocument}
                            setPersonHasDocument={props.setPersonHasDocument}
                            disableEdit={props.disableEdit}
                        />
                    )}
                </>
            }
            </Grid>
            <Box sx={{mt: 10}} />
        </>
    )
}
