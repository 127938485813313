import { Box, Button, Typography } from "@mui/material";
import React from "react";


interface IProps {
    handleBack: Function;
    handleNext: Function;
    handleOpenImportDialog: Function;
}


export const CompanyChangeStart:React.FC<IProps> = ({handleBack, handleNext, handleOpenImportDialog }) => {
    return(
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4" sx={{ mb: 3 }}>Unternehmensänderung</Typography>

            <Typography>
                Herzlich willkommen!
                <br/>Über unseren Dialog können Sie Änderungen in Bezug auf Ihr Unternehmen veranlassen. Wir begleiten Sie Schritt für Schritt.
                <p />
                Brauchen Sie zwischendurch eine Pause? Sie können Ihre Eingaben jederzeit über das Feld <i>Sichern</i> exportieren und speichern. 
                Setzen Sie Ihre Eingaben dann über die Klickfläche <i>Wiederherstellung</i> fort. 
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => handleOpenImportDialog()}
                    sx={{ mr: 1 }}
                >
                    Suche erneut starten
                </Button>

                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>
        </>
    )
}