import React from "react";
import { Box, Button, Grid, TextField, Typography } from "@mui/material";
import { IDocument } from "../../../../../../Interfaces/IDocument";
import { DocumentOverview } from "../../../../../Document/DocumentOverview";
import { IMortgage } from "../../../../../../Interfaces/IMortgage";



interface IProps {
    handleBack: Function;
    handleNext: Function;
    mortgageObject: IMortgage;
    setMortgageObject: Function;
    documentArray: IDocument[];
    setDocumentArray: Function;
}


export const MortgageDocuments:React.FC<IProps> = (props) => {
    const { ToBeDeleted, Beneficiary } = props.mortgageObject;

    return(
        <>
            <Box sx={{mt: 5}}/>

            <Typography variant="h4">
                Einzureichende(s) Dokument(e)
            </Typography>
            <Typography sx={{mb: 3}}>
               {Beneficiary ==="institution" && <>Bitte laden Sie das <b>Grundschuldformular</b> und den <b>Notarauftrag</b> hoch.</>}
               {Beneficiary ==="private" && <>Bitte laden Sie das.</>}
               {ToBeDeleted &&  <>Bitte laden Sie die Dokumente hoch, die Sie zur Löschung der Grundschuld erhalten haben (die sog. <b>Löschungsbewilligung</b>).</>}
            </Typography>
            <DocumentOverview
                allowRemove
                allowUpload
                documentArray={props.documentArray}
                setDocumentArray={props.setDocumentArray}
                title=""
            />
            <Box sx={{mt: 5}}/>
            <Box sx={{mt: 5}}/>
            <Typography sx={{mb: 3}}>
                Haben Sie sonstige Anmerkungen/Anregungen/Wünsche zum Beurkungungsauftrag, die Sie uns mitteilen möchten?
            </Typography>
            <Grid item xs={6} sm={4}>
                <TextField
                    label="Anmerkungen"
                    fullWidth
                    size="small"
                    multiline
                    rows={5}
                    value={props.mortgageObject.remarks}
                    onChange={(event: React.ChangeEvent<HTMLInputElement>) => props.setMortgageObject({...props.mortgageObject, remarks: event.target.value})}
                />
            </Grid>
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => props.handleBack()}
                    sx={{ mr: 1 }}
                >
                    Zurück
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button 
                 type="submit"  
                 variant="contained" 
                 onClick={() => props.handleNext()} 
                >
                    Weiter
                </Button>
            </Box>
        </>
    )
}