import React, { useState } from "react";
import { IRealEstate } from "../../../../../../Interfaces/IRealEstate";
import {Box, Button, Typography, Grid, Table, TableHead, TableRow, TableCell, TableBody} from "@mui/material";
import { RealEstateSummary } from "../Components/Summary/RealEstateSummary";
import { IDocument } from "../../../../../../Interfaces/IDocument";
import { IMortgage, IMortgageFullObject } from "../../../../../../Interfaces/IMortgage";
import { CustomCircularProgress } from "../../../../../generic/CustomCircularProgress";
import { IConfig } from "../../../../../../Interfaces/IConfig";
import { IMortgagePerson } from "../../../../../../Interfaces/IPerson";
import { MortgageDocumentSummary } from "../Components/Summary/MortgageDocumentSummary";
import { MortgagePersonSummary } from "../Components/Summary/MortgagePersonSummary";
import { IContactFullObject } from "../../../../../../Interfaces/IContact";
import { genderArray } from "../../../../../../StaticArraies/genderArray";
import { staticCountryArray } from "../../../../../../StaticArraies/staticCountryArray";
import {ClientInformation} from "../../../../../generic/ClientInformation";


interface IProps {
    configObject: IConfig;
    handleBack: Function;
    handleNext: Function;
    realEstate: IRealEstate;
    personArray: IMortgagePerson[];
    documentArray: IDocument[];
    mortgageObject: IMortgageFullObject;
    setMortgageObject: Function;
}

export const MortgageSummary:React.FC<IProps> = (props) => {
    const [isLoading, setIsLoading] = useState(false);

    if (isLoading) { return <CustomCircularProgress/> }
    else {
        return(
            <>
                
                <Typography variant="h4" sx={{mb: 3}}>Zusammenfassung</Typography>

                <Typography variant="h6" sx={{mt: 5}}>Eigentümer</Typography>

                <Table>
                    <TableHead>
                        <TableRow>
                            <TableCell>Nr.:</TableCell>
                            <TableCell>Geschlecht</TableCell>
                            <TableCell>Vorname</TableCell>
                            <TableCell>Nachname</TableCell>
                            <TableCell>Geburtsname</TableCell>
                            <TableCell>Geburtstag</TableCell>
                            <TableCell>Geburtsort</TableCell>
                            <TableCell>Nationalität</TableCell>
                        </TableRow>
                    </TableHead>

                    <TableBody>
                        {props.personArray.map((x,i) =>
                            <TableRow>
                                <TableCell>{i+1}</TableCell>
                                <TableCell>{genderArray.find(g => g.idGender == x.idGender)?.Gender}</TableCell>
                                <TableCell>{x.FirstName}</TableCell>
                                <TableCell>{x.LastName}</TableCell>
                                <TableCell>{x.Surname}</TableCell>
                                <TableCell>{x.Birthday}</TableCell>
                                <TableCell>{x.Birthplace}</TableCell>
                                <TableCell>{staticCountryArray.find(c => c.idCountry == x.idCountry)?.Nationality}</TableCell>
                            </TableRow>
                        )}
                    </TableBody>
                </Table>
    
                <RealEstateSummary 
                    mortgageObject={props.mortgageObject}
                />

                <Grid container spacing={2} sx={{ml: 0, mt: 5}}>                
                    <>
                        <Typography variant="h6">Details Grundschuld:</Typography>
                        <Grid item sm={12}><hr /></Grid>

                        <Grid item sm={4}><b>Neue/ zu löschende Grundschuld:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.ToBeDeleted ? "Zu löschen" : "Neu"}</Grid>

                        <Grid item sm={4}><b>Wird die Grundschuld zur Finanzierung eines Immobilienkaufs benötigt?</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.PropertyForBuying ? "Ja" : "Nein"}</Grid>

                        {props.mortgageObject.PropertyForBuying &&
                            <>
                                <Grid item sm={4}><b>Ist der Immobilienkauf bereits erfolgt?</b></Grid>
                                <Grid item sm={8}>{props.mortgageObject.PropertyOwned ? "Ja" : "Nein"}</Grid>
                                {props.mortgageObject.PropertyOwned &&
                                    <>
                                        <Grid item sm={4}><b>Urkundenverzeichnisnummer</b></Grid>
                                        <Grid item sm={8}>{props.mortgageObject.DocumentIndexNumber}</Grid>

                                        <Grid item sm={4}><b>Vorgangsnummer</b></Grid>
                                        <Grid item sm={8}>{props.mortgageObject.OperationNumber}</Grid>                       
                                    </>
                                }
                            </>
                        }

                        <Grid item sm={4}><b>Betrag der Grundschuld</b></Grid>
                        <Grid item sm={8}>{Number.parseFloat(props.mortgageObject.AmountMortgage).toLocaleString("de-DE", { style: "currency", currency: "EUR" })}</Grid>

                        <Grid item sm={4}><b>Grundschuldzinssatz</b></Grid>
                        <Grid item sm={8}>{(Number.parseFloat(props.mortgageObject.MortgageInterest)/100).toLocaleString("de-DE", { style: "percent" })}</Grid>

                        <Grid item sm={4}><b>Nebenleistungen</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.AdditionalServices}</Grid>

                        <Grid item sm={4}><b>Abschrift</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.Transcripts?.map((transcript: string) => {
                            switch (transcript) {
                                case "simple_transcription":
                                    return "Einfache Abschrift"

                                case "certified_copy":
                                    return "Beglaubigte Abschrift"

                                case "simple_execution":
                                    return "Einfache Ausfertigung"

                                case "enforceable_copy":
                                    return "Vollstreckbare Ausfertigung"
                                default:
                                    return "";
                            }
                        }).join(", ")}</Grid>

                        <Grid item sm={4}><b>Grundschuld nach §873 Abs. 2 BGB entgegengenommen</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.Paragraph ? "Ja" : "Nein"}</Grid>
                    </>
                </Grid>

                <MortgagePersonSummary
                    beneficiary={props.mortgageObject.BeneficiaryDetails}
                    contactObject={props.mortgageObject.ContactObject as IContactFullObject}
                />

                <MortgageDocumentSummary
                    documentArray={props.documentArray}
                />

                <Grid container sx={{mt: 5}}>
                    <Grid item sm={4}><b>Anmerkungen zum Beurkundungsauftrag:</b></Grid>
                    <Grid item sm={8}>{props.mortgageObject.remarks && props.mortgageObject.remarks.length ? props.mortgageObject.remarks : "Keine"}</Grid>
                </Grid>


            </>
        )
    }
}