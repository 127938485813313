import React, { SyntheticEvent, useEffect, useState } from "react";
import { IPostcode } from "../../Interfaces/IPostcode";
import { Autocomplete, CircularProgress, TextField } from "@mui/material";


interface IProps {
  idPostcode: number|null;
  setIdPostcode: Function;
  disabledEdit?: boolean;
  required?: boolean;
  allowNull?: boolean;

  label? : string;
}


function sleep(delay = 0) {
    return new Promise((resolve) => {
      setTimeout(resolve, delay);
    });
  }


const PostcodeSearchV2:React.FC<IProps> = (props) => {
    const [open, setOpen] = useState(false);
    const [currentPostcodeObject,setCurrentPostcodeObject] = useState<IPostcode|null>(null);
    const [options, setOptions] = useState<readonly IPostcode[]>([]);
    //
    // const loading = open && options.length === 0;
    const [loading,setLoading] = useState(false);
    const [searchTerm,setSearchTerm] = useState("");

    useEffect(() => {
      if (props.idPostcode !== null && currentPostcodeObject === null) {
          setLoading(true);
    

        fetch(`/api/postcode/${props.idPostcode}`, {
          headers: {
            "X-APIKEY-LOCAL" : "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
          }
        })
        .then(res => {
          if (res.status === 200) { return res.json()}
          else {throw Error(`ERROR GET: ${res.status}`)}
        })
        .then(res => {
          setOptions([res]);
          setCurrentPostcodeObject(res);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        })
      }
      else if (props.idPostcode === null && currentPostcodeObject !== null) {
        setCurrentPostcodeObject(null);
      }
    },[props.idPostcode])


    async function queryBackend(localSearchTerm:string) {
      setLoading(true);

      if (localSearchTerm === "") {
        setLoading(false);
        setOptions([]);
      } else {
        fetch(`/api/postcode/public/${localSearchTerm}`, {
          headers: {
            "X-APIKEY-LOCAL" : "2YoqmmvC5upGaBedZ12ES8pZYj7FZqY",
          }
        })
        .then(res => {
          if (res.status === 200) { return res.json()}
          else {throw Error(`ERROR GET: ${res.status}`)}
        })
        .then(res => {
          setOptions(res);
          setLoading(false);
        })
        .catch((error) => {
          console.error(error);
        })
      }
    }

    function onChange(event: SyntheticEvent, newObject:IPostcode|null) {
      setCurrentPostcodeObject(newObject);
      if (newObject !== null) {
        props.setIdPostcode(newObject.idPostcode);
      }
      else {
        if (props.allowNull) {
          props.setIdPostcode(null);
        } else {
          props.setIdPostcode(-1);
        }

      }
    }


    function onInputChange(event: SyntheticEvent | null, searchTerm:string) {
      if (event !== null && event.type === "change") {
        setLoading(true);
        setSearchTerm(searchTerm);
      }
    }


    useEffect(() => {
      const timeOutId = setTimeout(() => queryBackend(searchTerm), 500);
      return () => clearTimeout(timeOutId);
    },[searchTerm])

    useEffect(() => {
     console.log(props.label)
    });


    return (
        <Autocomplete
          id="searchbar-postcode"
          fullWidth
          open={open}
          onOpen={() => {
            setOpen(true);
          }}
          onClose={() => {
            setOpen(false);
          }}
          disabled={props.disabledEdit !== undefined && props.disabledEdit === true}
          value={currentPostcodeObject}
          isOptionEqualToValue={(option, value) => option.idPostcode === value.idPostcode}
          getOptionLabel={(option) => `${option.Postcode} ${option.City}` }
          loadingText="Laden..."
          options={options}
          loading={loading}
          onInputChange={onInputChange}
          onChange={onChange}
          renderInput={(params) => (
            <TextField
              {...params}
              label={(props.label == undefined) ? "Postleitzahl" : props.label}
              size="small"
              required={(props.required == undefined) ? true : props.required}
              InputProps={{
                ...params.InputProps,
                endAdornment: (
                  <React.Fragment>
                    {loading ? <CircularProgress color="inherit" size={20} /> : null}
                    {params.InputProps.endAdornment}
                  </React.Fragment>
                ),
              }}
            />
          )}
        />
      );
}
export default PostcodeSearchV2;

