import React, { useEffect, useState } from "react";
import { IShareHolder, IShareHolderFullObject } from "../../../../../../Interfaces/IShareHolder";
import { ICompanyFormationHasShareHolder } from "../../../../../../Interfaces/ICompanyFormationHasShareHolder";
import { Box, Grid, Typography } from "@mui/material";


export interface IProps {
    shareHolderArray: IShareHolderFullObject[];
    shareHolderRelation: ICompanyFormationHasShareHolder[];
}


export const RepresentationSummary:React.FC<IProps> = (props) => {
    const [mgmt,setMgmt] = useState<number[]>([]);
    const [procura,setProcura] = useState<number[]>([]);

    useEffect(() => {
        setMgmt(props.shareHolderRelation.filter(x => x.idShareHolderType === 2).map(x => x.idShareHolder))
        setProcura(props.shareHolderRelation.filter(x => x.idShareHolderType === 3).map(x => x.idShareHolder))
    },[props.shareHolderRelation])

    const genTsx = (localArray:number[],title:string) => {
        return(
            <>
                <Grid item sm={4}><b>{title}</b></Grid>
                {localArray.map((x,i) => {
                    let testObject = props.shareHolderArray.find(y => y.idShareHolder === x)

                    if (testObject === undefined) {
                        return(<>ERROR</>)
                    } else {
                        return(
                            <>
                                {(i !== 0) && <Grid item sm={4}></Grid>}

                                <Grid item sm={8}>
                                    {(testObject.idBusinessForm === 1) ? `${testObject.FirstName} ${testObject.LastName}` : testObject.CompanyObject.Company}
                                </Grid>
                            </>
                        )
                    }
                })}
            </>
        )
    }

    return(
        <>
            <Box sx={{mt: 10}} />
            <Typography variant="h6" sx={{mb: 3}}>
                Vertretung
            </Typography>
            <Grid container sx={{pl: 10}} spacing={2}>
                {genTsx(mgmt,"Geschäftsführung")}
                {genTsx(procura,"Prokura")}
            </Grid>
        </>
    )
}