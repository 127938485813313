import React, { useState } from "react";
import { IPersonHasDocument } from "../../../../../../Interfaces/IPersonHasDocument";
import { IInheritHasPerson } from "../../../../../../Interfaces/IPerson";
import { Box, Button } from "@mui/material";
import { InheritPerson, getInheritPerson } from "../Components/InheritPerson";
import { IRequiredFiles } from "../Components/InheritDocument";
import { checkIsDisabled } from "../../../../../core/getDisabledHelper_Inherit";
import { IInherit } from "../../../../../../Interfaces/IInherit";



interface IProps {
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    inheritObject: IInherit;
    setInheritObject: Function;
    handleNext: Function;
    handleBack: Function;
}


export const Inherit:React.FC<IProps> = (props) => {
    const [currentPerson, setCurrentPerson] = useState(getInheritPerson(-1, props.personArray))


    const handleSubmit = (e: React.FormEvent) => {
        e.preventDefault();
        props.setPersonArray([
            ...props.personArray.map(x => x.idPerson === -1 ? currentPerson : x)
        ])

        props.handleNext();
    }

    return (
        <>
            <form onSubmit={handleSubmit}>
                <Box sx={{mt: 5}}/>

                <InheritPerson
                    currentPerson={currentPerson}
                    setCurrentPerson={setCurrentPerson}
                    personArray={props.personArray}
                    inheritObject={props.inheritObject}
                    setInheritObject={props.setInheritObject}
                    title="Erblasser"
                    requiredFiles={[{Title: "Sterbeurkunde", isOptional: false, idPersonDocumentType: 1}] as IRequiredFiles[]}
                    personHasDocument={props.personHasDocument}
                    setPersonHasDocument={props.setPersonHasDocument}
                    titleAdress="Letzter Wohnsitz"
                    forceDeathSettings={true}
                    showDeathSettings={false}
                    forceAdress
                    disableDeathDateWarning
                />


                <Box sx={{display: 'flex', flexDirection: 'row', pt: 2}}>
                    <Button
                        variant="outlined"
                        onClick={() => props.handleBack()}
                        sx={{mr: 1}}
                    >
                        Zurück
                    </Button>
                    <Box sx={{flex: '1 1 auto'}}/>
                    <Button type="submit" variant="contained">
                        Weiter
                    </Button>
                </Box>
            </form>
        </>
    )
}