import React, {useEffect, useState} from "react";
import {ICompanyFormation} from "../../../../../../Interfaces/ICompanyFormation";
import {
    Dialog,
    DialogContent, DialogTitle,
    IconButton,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
    Box, Grid, Button, Collapse, Alert, AlertTitle
} from "@mui/material";
import {IShareHolderFullObject} from "../../../../../../Interfaces/IShareHolder";
import {ICompanyFormationShare} from "../../../../../../Interfaces/ICompanyFormationShare";
import {ICompanyFormationHasShareHolder} from "../../../../../../Interfaces/ICompanyFormationHasShareHolder";
import {SelectShareHolderDialog} from "../gmbh/Shares/SelectShareHolderDialog";
import {SharesMainRow} from "../gmbh/Shares/SharesMainRow";
import {Add, Delete, Edit} from "@mui/icons-material";
import {ShareHolderEdit} from "../ShareHolder/ShareHolderEdit";
import {getBirthday} from "../../../../../core/standardFunctions";


interface IProps {
    companyFormationObject: ICompanyFormation;
    setCompanyFormationObject: Function;
    setTxt: Function;
    currentStep: number;
    handleBack: Function;
    handleNext: Function;
    shareHolderArray: IShareHolderFullObject[];
    setShareHolderArray: Function;
    companyFormationShareArray: ICompanyFormationShare[];
    setCompanyFormationShareArray: Function;
    shareHolderRelation: ICompanyFormationHasShareHolder[];
    setShareHolderRelation: Function;
}

export const GbRShareholderWithoutShares: React.FC<IProps> = (props) => {
    const handleDelete = (id: Number) => {
        props.setShareHolderArray(props.shareHolderArray.filter(x => x.idShareHolder !== id))
    }
    const handleClose = () => {
        setIdShareHolder(null);
    }
    const options: Intl.DateTimeFormatOptions = {
        year: "numeric",
        month: "long",
        day: "numeric",
    };

    const [hasSoftWarning, setHasSoftWarning] = useState(false);

    useEffect(() => {
        if (props.shareHolderArray.length < 2) {
            setHasSoftWarning(true)
        } else {
            setHasSoftWarning(false)
        }
    }, [props.shareHolderArray.length]);


    const [idShareHolder, setIdShareHolder] = useState<number | null>(null);
    return (
        <>
            <Collapse in={hasSoftWarning}>
                <Alert severity="warning">
                    <AlertTitle>Warnung</AlertTitle>
                    Zur Gründung einer GbR werden mindestens zwei Gründer benötigt.
                </Alert>
            </Collapse>

            <Dialog open={idShareHolder !== null} onClose={() => setIdShareHolder(null)} maxWidth="md" fullWidth>
                {(idShareHolder !== null) &&
                    <>
                        <DialogTitle>Person bearbeiten</DialogTitle>
                        <Box sx={{mt: 2}}>
                            <DialogContent>
                                <ShareHolderEdit idShareHolder={idShareHolder} handleClose={handleClose}
                                                 shareHolderArray={props.shareHolderArray}
                                                 setShareHolderArray={props.setShareHolderArray}></ShareHolderEdit>
                            </DialogContent>
                        </Box>
                    </>
                }
            </Dialog>

            <SelectShareHolderDialog
                idShareHolderType={1}
                shareHolderArray={props.shareHolderArray}
                setShareHolderArray={props.setShareHolderArray}
                shareHolderRelation={props.shareHolderRelation}
                setShareHolderRelation={props.setShareHolderRelation}
                //
                companyFormationObject={props.companyFormationObject}
                companyFormationShareArray={props.companyFormationShareArray}
                setCompanyFormationShareArray={props.setCompanyFormationShareArray}
            />

            <Table sx={{mt: 5}}>
                <TableHead>
                    <TableRow>
                        <TableCell>Gesellschaft(er)</TableCell>
                        <TableCell>Geboren am</TableCell>
                        <TableCell>Anschrift</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {props.shareHolderArray.map((shareholder) => (
                        <TableRow
                            key={"shar"}
                            sx={{'&:last-child td, &:last-child th': {border: 0}}}
                        >
                            <TableCell component="th" scope="row">
                                {(shareholder.idBusinessForm === 1) ?
                                    <>{shareholder.FirstName} {shareholder.LastName}</> : <>{shareholder.CompanyObject.Company}</>
                                }
                            </TableCell>

                            <TableCell component="th" scope="row">
                                {
                                    getBirthday(shareholder)
                                }
                            </TableCell>
                            <TableCell component="th" scope="row">
                                {(shareholder.idBusinessForm === 1) ?
                                    <> {shareholder.Street} {shareholder.StreetNr} {shareholder.City} {shareholder.Postcode}</> :
                                    <> {shareholder.CompanyObject.Street} {shareholder.CompanyObject.StreetNr} {shareholder.CompanyObject.City} {shareholder.CompanyObject.Postcode}</>
                                }
                            </TableCell>
                            <TableCell sx={{width: 90}}>
                                <IconButton onClick={() => {
                                    {
                                        setIdShareHolder(Number(shareholder.idShareHolder))
                                    }
                                }}><Edit/></IconButton>
                                <IconButton onClick={() => {
                                    {
                                        props.setShareHolderArray(props.shareHolderArray.filter(x => x.idShareHolder !== shareholder.idShareHolder))
                                    }
                                }}><Delete/></IconButton>
                            </TableCell>
                        </TableRow>
                    ))}
                </TableBody>
            </Table>


            <Box sx={{mt: 5, display: 'flex', flexDirection: 'row', pt: 2}}>
                <Button
                    variant="outlined"
                    onClick={() => props.handleBack()}
                    sx={{mr: 1}}
                >
                    Zurück
                </Button>
                <Box sx={{flex: '1 1 auto'}}/>
                <Button
                    onClick={() => props.handleNext()}
                    disabled={hasSoftWarning}
                    type="submit" variant="contained"
                >
                    Weiter
                </Button>
            </Box>

        </>
    )
}