import React from "react";
import { IInherit } from "../../../../../../Interfaces/IInherit";
import { IInheritHasPerson } from "../../../../../../Interfaces/IPerson";
import { Box, Button, Typography } from "@mui/material";
import { StrangersTable } from "../Components/Strangers/StrangersTable";
import { IPersonHasDocument } from "../../../../../../Interfaces/IPersonHasDocument";


interface IProps {
    inheritObject: IInherit;
    personArray: IInheritHasPerson[];
    setPersoArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    handleBack: Function;
    handleNext: Function;
}



export const Strangers:React.FC<IProps> = (props) => {

    return(
        <>
            <Typography variant="h5">
                Erfassung sonstiger Personen 
            </Typography>

            <Typography variant="body1" sx={{mt: 2, mb: 3}}>
                Bitte erfassen Sie alle weiteren Personen, welche in dem Testament des Erblassers bestimmt sind.
            </Typography>

            <StrangersTable
                inheritObject={props.inheritObject}
                personArray={props.personArray}
                setPersoArray={props.setPersoArray}
                personHasDocument={props.personHasDocument}
                setPersonHasDocument={props.setPersonHasDocument}
            />

            <Box sx={{mt: 10}} />
            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => props.handleBack()}
                    sx={{ mr: 1 }}
                >
                    Zurück
                </Button>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => props.handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>

        </>
    )
}