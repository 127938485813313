import React, { useEffect, useState } from "react";
import { IInheritHasPerson } from "../../../../../../../Interfaces/IPerson";
import { IChildren } from "../../../../../../../Interfaces/IChildren";
import { CustomeSlide } from "../../../../../../core/CustomeSlide";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { ChildrenTable } from "./ChildrenTable";
import { Box, Button } from "@mui/material";
import { IInherit } from "../../../../../../../Interfaces/IInherit";
import { IMarriage } from "../../../../../../../Interfaces/IMarriage";


interface IProps {
    inheritObject: IInherit;
    parrentPersonArray: IInheritHasPerson[];
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    childrenArray: IChildren[];
    setChildrenArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    marriageArray: IMarriage[];
    handleNext: Function;
    handleBack: Function;
    depth?: number;
}


export const ChildrenDriver:React.FC<IProps> = (props) => {
    const getLocalChildrenArray = () => {
        return props.childrenArray
            .filter(x => props.parrentPersonArray.map(y => y.idPerson).indexOf(x.Parent1_idPerson) > -1)
    }



    const [currentChildrenPage, setCurrentChildrenPage] = useState(
        props.personArray
            .filter(x => getLocalChildrenArray().map(y => y.Children_idPerson).indexOf(x.idPerson) > -1)
            .filter(x => x.idPerson !== -1).filter(x => x.DateOfDeath !== null) 
            .length === 0 ? 0 : 1
    );
    /*const [localChildrenArray, setLocalChildrenArray] = useState(
        props.childrenArray
            .filter(x => props.parrentPersonArray.map(y => y.idPerson).indexOf(x.Parent1_idPerson) > -1)
    )
    */
    const [deathChildren, setDeathChildren] = useState<IInheritHasPerson[]>([]);
    /*
    useEffect(() => {
        props.setChildrenArray([
            ...props.childrenArray
                .filter(x => props.parrentPersonArray.map(y => y.idPerson).indexOf(x.Parent1_idPerson) === -1),
            ...localChildrenArray
        ])
    },[localChildrenArray])
    */



    useEffect(() => {
        let testPersonArray = props.personArray
            .filter(x => getLocalChildrenArray().map(y => y.Children_idPerson).indexOf(x.idPerson) > -1);
        setDeathChildren([...testPersonArray.filter(x => x.idPerson !== -1).filter(x => x.DateOfDeath !== null) ])
    },[props.childrenArray,props.personArray])


    const handleNext = () => {
        // if: wenn tote kinder, dann Iteriere
        if  (deathChildren.length > 0) {
            setCurrentChildrenPage(1);
        // elif: wenn Children Leer, dann springe auf Eltern/Fehler
        }
        else if (getLocalChildrenArray().filter(x => x.Children_idPerson !== -1).length === 0) {
            props.handleNext()
        }
        else if (props.inheritObject.hasTestament ) {
            props.handleNext(6)
        }
        // elfi: wenn alle Kinder leben
        else if (deathChildren.length === 0 ) {
            props.handleNext(7)
        } 
        else {
            props.handleNext()
        }

    }

    const handleBackForChild = () => {
        setCurrentChildrenPage(0);
    }


    return(
        <>

            <CustomeSlide currentPage={currentChildrenPage} pageNumber={0} lastCurrentPage={0} appear={false}>
                <>
                    <ChildrenTable
                        inheritObject={props.inheritObject}
                        firstParentArray={props.parrentPersonArray}
                        personArray={props.personArray}
                        setPersonArray={props.setPersonArray}
                        personHasDocument={props.personHasDocument}
                        setPersonHasDocument={props.setPersonHasDocument}
                        childrenArray={props.childrenArray}
                        setChildrenArray={props.setChildrenArray}
                        marraigeArray={props.marriageArray}
                    />
                    <Box sx={{ mt: 10 }} />
                    <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                        <Button
                            variant="outlined"
                            onClick={() => props.handleBack()}
                            sx={{ mr: 1 }}
                        >
                            Zurück
                        </Button>
                        <Box sx={{ flex: '1 1 auto' }} />
                        <Button type="submit" variant="contained" onClick={handleNext}>
                            Weiter
                        </Button>
                    </Box>
                </>
            </CustomeSlide>

            <CustomeSlide currentPage={currentChildrenPage} pageNumber={1} lastCurrentPage={1}>
                <ChildrenDriver
                    inheritObject={props.inheritObject}
                    key={`children-child-${deathChildren.length}-${(props.depth === undefined) ? 0 : props.depth+1}`}
                    depth={(props.depth === undefined) ? 0 : props.depth+1}
                    parrentPersonArray={deathChildren}
                    personArray={props.personArray}
                    setPersonArray={props.setPersonArray}
                    personHasDocument={props.personHasDocument}
                    setPersonHasDocument={props.setPersonHasDocument}
                    childrenArray={props.childrenArray}
                    setChildrenArray={props.setChildrenArray}
                    marriageArray={props.marriageArray}
                    handleBack={handleBackForChild}
                    handleNext={props.handleNext}
                />
            </CustomeSlide>
        </>
    )
}