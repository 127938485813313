import {Alert, AppBar, Box, Button, Dialog, DialogContent, Drawer, Step, StepLabel, Stepper, Toolbar, Typography} from "@mui/material";
import React, {useState} from "react";
import {RestoreProgress} from "../RestoreProgress";
import {SaveProgress} from "../SaveProgress";
import {CustomeSlide} from "../../../core/CustomeSlide";
import {PurchaseContractStart} from "./Dialog/PurchaseContractStart";
import {PurchaseContractRealEstate} from "./Dialog/PurchaseContractRealEstate";
import {IShareHolderFullObject} from "../../../../Interfaces/IShareHolder";
import {IRealEstate} from "../../../../Interfaces/IRealEstate";
import { useFetch } from "../../../../hooks/useFetch";
import { IPropertyType } from "../../../../Interfaces/IPropertyType";
import { IInventoryOption } from "../../../../Interfaces/IInventoryOption";
import { IRealEstateUseType } from "../../../../Interfaces/IRealEstateUseType";
import { CustomCircularProgress } from "../../../generic/CustomCircularProgress";
import { IRealEstateHasPropertyType } from "../../../../Interfaces/IRealEstateHasPropertyType";
import { IRealEstateHasInventoryOption } from "../../../../Interfaces/IRealEstateHasInventoryOption";
import { useHotkeys } from "react-hotkeys-hook";
import { JSONTree } from "react-json-tree";
import { IPurchaseContractHasPerson } from "../../../../Interfaces/IPerson";
import { PurchaseContractPersonOverview } from "./Components/Person/PurchaseContractPersonOverview";
import { IDocument } from "../../../../Interfaces/IDocument";
import { PurchaseContractDocuments } from "./Dialog/PurchaseContractDocuments";
import { PurchaseContractSummary } from "./Dialog/PurchaseContractSummary";
import { IPurchaseContract } from "../../../../Interfaces/IPurchaseContract";
import Send from "../../../generic/Send";
import { IConfig } from "../../../../Interfaces/IConfig";
import { DialogFrame } from "../../../core/DialogFrame";





interface IProps {
    configObject: IConfig;
}



export const PurchaseContractDialog: React.FC<IProps> = ({configObject}) => {
    const [propertyTypeArray, setPropertyTypeArray, wasSuccessfullyPropertyTypeArray] = useFetch<IPropertyType[]>("/propertytype");
    const [inventoryOptionArray, setInventoryOptionArray, wasSuccessfullyInventoryOption] = useFetch<IInventoryOption[]>("/inventoryoption");
    const [realEstateUseTypeArray, setRealEstateUseTypeArray, wasSuccessfullyRealEstateUseTypeArray] = useFetch<IRealEstateUseType[]>("/realestateusetype");

    const [purchaseContractEdit,setPurchaseContractEdit] = useState<IPurchaseContract>({
        idLegalTransaction: -1,
        idPurchaseContract: -1,
        idPurchaseContractType: 1,
        ThirdPartyClauseText: null,
        withThirdPartyClause: false
    } as IPurchaseContract)

    const [txt, setTxt] = useState("");
    const [currentPage, setCurrentPage] = useState(0);
    const [lastCurrentPage, setLastCurrentPage] = useState(-1);

    const [realEstateArray, setRealEstateArray] = useState<IRealEstate[]>([]);
    const [realEstateHasPropertyTypeArray,setRealEstateHasPropertyTypeArray] = useState<IRealEstateHasPropertyType[]>([]);
    const [realEstateHasInventroyOptionArray,setRealEstateHasInventroyOptionArray] = useState<IRealEstateHasInventoryOption[]>([]);
    const [personArray,setPersonArray] = useState<IPurchaseContractHasPerson[]>([]);
    const [documentArray, setDocumentArray] = useState<IDocument[]>([]);

    const [isDebugEnabled, setIsDebugEnabled] = useState(false);
    const [isOpenJsonDebug, setIsOpenJsonDebug] = useState(false);


    useHotkeys('shift+d', () => setIsDebugEnabled(!isDebugEnabled));


    /* ###############################  BEGIN SAVE AND RESTORE ############################### */
    const getDataToSave = () => {
        let tempDataToSave = {
            //This three Array are not necessary as of my opinion / Florian, they are loaded each time this Components get loaded,
            //so no need in saving them and restoring them!
            //propertyTypeArray: propertyTypeArray,
            //inventoryOptionArray: inventoryOptionArray,
            //realEstateUseTypeArray: realEstateUseTypeArray,
     
            //txt: txt,
            currentPage: currentPage,
            lastCurrentPage: lastCurrentPage,
            purchaseContractEdit: purchaseContractEdit,
            realEstateArray: realEstateArray,
            realEstateHasPropertyTypeArray: realEstateHasPropertyTypeArray,
            realEstateHasInventroyOptionArray: realEstateHasInventroyOptionArray,
            personArray: personArray,
            documentArray: documentArray,
        }
        return tempDataToSave
    }

    const restoreSetterFunction  = (uplodatedFile: any) => {
       /* Same counts for this 3 if checks / Florian no need!
       if ("propertyTypeArray" in uplodatedFile) {
            setPropertyTypeArray(uplodatedFile["propertyTypeArray"])
        }
        if ("inventoryOptionArray" in uplodatedFile) {
            setInventoryOptionArray(uplodatedFile["inventoryOptionArray"])
        }
        if ("realEstateUseTypeArray" in uplodatedFile) {
            setRealEstateUseTypeArray(uplodatedFile["realEstateUseTypeArray"])
        }
        if ("txt" in uplodatedFile) {
            setTxt(uplodatedFile["txt"])
        }
        */
        if ("currentPage" in uplodatedFile) {
            setCurrentPage(uplodatedFile["currentPage"])
        }
        if ("lastCurrentPage" in uplodatedFile) {
            setLastCurrentPage(uplodatedFile["lastCurrentPage"])
        }
        if ("purchaseContractEdit" in uplodatedFile && uplodatedFile["purchaseContractEdit"] !== purchaseContractEdit) {
            setPurchaseContractEdit(uplodatedFile["purchaseContractEdit"])
        }
        if ("realEstateArray" in uplodatedFile && uplodatedFile["realEstateArray"] !== realEstateArray) {
            setRealEstateArray(uplodatedFile["realEstateArray"])
        }
        if ("realEstateHasPropertyTypeArray" in uplodatedFile && uplodatedFile["realEstateHasPropertyTypeArray"] !== realEstateHasPropertyTypeArray) {
            setRealEstateHasPropertyTypeArray(uplodatedFile["realEstateHasPropertyTypeArray"])
        }
        if ("realEstateHasInventroyOptionArray" in uplodatedFile && uplodatedFile["realEstateHasInventroyOptionArray"] !== realEstateHasInventroyOptionArray) {
            setRealEstateHasInventroyOptionArray(uplodatedFile["realEstateHasInventroyOptionArray"])
        }
        if ("personArray" in uplodatedFile && uplodatedFile["personArray"] !== personArray) {
            setPersonArray(uplodatedFile["personArray"])
        }
        if ("documentArray" in uplodatedFile && uplodatedFile["documentArray"] !== documentArray) {
            setDocumentArray(uplodatedFile["documentArray"])
        }
    }



    const handleBack = () => {
        setLastCurrentPage(currentPage);
        setCurrentPage(currentPage - 1);
    }
    const handleNext = () => {
        setLastCurrentPage(currentPage);
        setCurrentPage(currentPage + 1);
    }

    if (
        !wasSuccessfullyPropertyTypeArray
        || !wasSuccessfullyInventoryOption
        || !wasSuccessfullyRealEstateUseTypeArray
    ) { return <Alert severity="error">Es ist ein Fehler aufgetreten!</Alert> }
    else if (
        propertyTypeArray === undefined
        || inventoryOptionArray === undefined
        || realEstateUseTypeArray === undefined
    ) { return <CustomCircularProgress/> } 
    else {
        return (
            <>
            <DialogFrame
                configObject={configObject}
                title="Formular Kaufvertrag"
                currentPage={currentPage}
                stepperArray={[
                    <Step key={`purchaseContract-0`} completed={currentPage > 0}><StepLabel>Kaufvertrag</StepLabel></Step>,
                    <Step key={`purchaseContract-1`} completed={currentPage > 1}><StepLabel>Vertragsgegenstände</StepLabel></Step>,
                    <Step key={`purchaseContract-2`} completed={currentPage > 2}><StepLabel>Verkäufer/innen</StepLabel></Step>,
                    <Step key={`purchaseContract-3`} completed={currentPage > 3}><StepLabel>Käufer/innen</StepLabel></Step>,
                    <Step key={`purchaseContract-4`} completed={currentPage > 4}><StepLabel>Dokumente</StepLabel></Step>,
                    <Step key={`purchaseContract-5`} completed={currentPage > 5}><StepLabel>Zusammenfassung</StepLabel></Step>
                ]}
                headerButtonArray={[
                    <RestoreProgress key="inherit-import" setterFunction={restoreSetterFunction}></RestoreProgress>,
                    <SaveProgress key="inherit-export" idLegaltransaction={1} dataToSave={getDataToSave()} filename={"Kaufvertrag"}></SaveProgress>
                ]}
                txt={txt}
            >
                <CustomeSlide currentPage={currentPage} pageNumber={0} lastCurrentPage={lastCurrentPage} appear={false}>
                    <PurchaseContractStart handleBack={handleBack} handleNext={handleNext}/>
                </CustomeSlide>


                <CustomeSlide currentPage={currentPage} pageNumber={1} lastCurrentPage={lastCurrentPage}>
                    <PurchaseContractRealEstate 
                        configObject={configObject}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        realEstateArray={realEstateArray}
                        setRealEstateArray={setRealEstateArray}
                        realEstateHasPropertyTypeArray={realEstateHasPropertyTypeArray}
                        setRealEstateHasPropertyTypeArray={setRealEstateHasPropertyTypeArray}
                        propertyTypeArray={propertyTypeArray}
                        realEstateHasInventroyOptionArray={realEstateHasInventroyOptionArray}
                        setRealEstateHasInventroyOptionArray={setRealEstateHasInventroyOptionArray}
                        inventoryOptionArray={inventoryOptionArray}
                        realEstateUseTypeArray={realEstateUseTypeArray}
                        personArray={personArray}
                        setPersonArray={setPersonArray}
                    />
                </CustomeSlide>


                <CustomeSlide currentPage={currentPage} pageNumber={2} lastCurrentPage={lastCurrentPage}>
                    <PurchaseContractPersonOverview
                        handleBack={handleBack}
                        handleNext={handleNext}

                        idPruchaseContractRelationType={1}
                        personArray={personArray}
                        setPersonArray={setPersonArray}
                        
                        title="Verkäufer/innen"
                        text="Bitte geben Sie alle Verkäufer/innen an."
                    />
                </CustomeSlide>


                <CustomeSlide currentPage={currentPage} pageNumber={3} lastCurrentPage={lastCurrentPage}>
                    <PurchaseContractPersonOverview
                        handleBack={handleBack}
                        handleNext={handleNext}

                        idPruchaseContractRelationType={5}
                        personArray={personArray}
                        setPersonArray={setPersonArray}
                        
                        title="Käufer/innen"
                        text="Bitte geben Sie alle Käufer/innen an."
                    />
                </CustomeSlide>


                <CustomeSlide currentPage={currentPage} pageNumber={4} lastCurrentPage={lastCurrentPage}>
                    <PurchaseContractDocuments
                        handleBack={handleBack}
                        handleNext={handleNext}

                        documentArray={documentArray}
                        setDocumentArray={setDocumentArray}
                    />
                </CustomeSlide>


                <CustomeSlide currentPage={currentPage} pageNumber={5} lastCurrentPage={lastCurrentPage}>
                    <PurchaseContractSummary 
                        configObject={configObject}
                        handleBack={handleBack}
                        handleNext={handleNext}
                        realEstateArray={realEstateArray}
                        realEstateHasPropertyTypeArray={realEstateHasPropertyTypeArray}
                        propertyTypeArray={propertyTypeArray}
                        realEstateHasInventroyOptionArray={realEstateHasInventroyOptionArray}
                        inventoryOptionArray={inventoryOptionArray}
                        realEstateUseTypeArray={realEstateUseTypeArray}
                        personArray={personArray}
                        documentArray={documentArray}
                        purchaseContractEdit={purchaseContractEdit}
                        setPurchaseContractEdit={setPurchaseContractEdit}
                    />
                </CustomeSlide>


                <CustomeSlide currentPage={currentPage} pageNumber={6} lastCurrentPage={lastCurrentPage}>
                    <Send/>
                </CustomeSlide>
            </DialogFrame>

                        <Dialog
                            open={isOpenJsonDebug}
                            onClose={() => setIsOpenJsonDebug(false)}
                            maxWidth="lg" fullWidth
                        >
                            <DialogContent>
                                <JSONTree data={{
                                    realEstateArray: realEstateArray,
                                    realEstateHasPropertyTypeArray: realEstateHasPropertyTypeArray,
                                    realEstateHasInventroyOptionArray: realEstateHasInventroyOptionArray,
                                    personArray: personArray
                                }} />
                            </DialogContent>
                        </Dialog>


                    {
                        (isDebugEnabled) &&
                        <>
                            <b>DEBUG</b>
                            <br />{`currentPage: ${currentPage}`}
                            <br /><Button onClick={() => setCurrentPage(1)}>Zurück zur ersten Seite</Button>
                            <br /><Button onClick={() => setIsOpenJsonDebug(true)}>Json Tree</Button>
                        </>
                    }

            </>
    
        )
    }
}
