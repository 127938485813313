import { Box, Button, Typography } from "@mui/material";
import React from "react";


interface IProps {
    handleBack: Function;
    handleNext: Function;
    handleOpenImportDialog: Function;
}


export const RAChangeStart:React.FC<IProps> = (props) => {
    return(
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4" sx={{ mb: 3 }}>Vereinsänderung</Typography>

            <Typography>
                Herzlich willkommen auf unserer Seite zur Änderung Ihrers Vereins! Hier erfahren Sie alles,
                was Sie benötigen, um den Änderungsprozess erfolgreich abzuschließen. Wir begleiten Sie Schritt
                für Schritt und stehen Ihnen bei Fragen jederzeit zur Verfügung.
                <p />
                <i>*Sie können ihren Fortschitt über "Sichern" exportieren und jederzeit nahtlos weitermachen</i>
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Button
                    variant="outlined"
                    onClick={() => props.handleOpenImportDialog()}
                    sx={{ mr: 1 }}
                >
                    Suche erneut starten
                </Button>

                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => props.handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>
        </>
    )
}