import { IRealEstate } from "../../../../../../Interfaces/IRealEstate";

export const getIsValidatedAdress = (realEstateObject:IRealEstate) => {
    return (
        (
            (
                realEstateObject.Street !== null && realEstateObject.Street !== ""
                && realEstateObject.StreetNr !== null && realEstateObject.StreetNr !== ""
                && realEstateObject.idPostcode !== null && realEstateObject.idPostcode > 0
            )
            || 
            (
                realEstateObject.Latitude !== null
                && realEstateObject.Longitude !== null
            )
        ) && (
            realEstateObject.hasPartialArea == false
            || (
                realEstateObject.hasPartialArea && realEstateObject.PaymentMeasurement_idPurchaseContractRelationType !== null
                && (
                    (realEstateObject.isMeasured && realEstateObject.Area !== null)
                    || (!realEstateObject.isMeasured && realEstateObject.OrderMeasurement_idPurchaseContractRelationType !== null)
                )
            )
        )

    )
}