import React, { useState } from "react";
import { IChildrenTableColDef } from "./ChildrenTable";
import { Box, Button, Dialog, DialogActions, DialogContent, Grid, IconButton, MenuItem, Tab, TableCell, TableRow, Tabs, TextField, Typography } from "@mui/material";

import { Delete, Edit } from "@mui/icons-material";
import { IChildren } from "../../../../../../../Interfaces/IChildren";
import { IInheritHasPerson, IPerson } from "../../../../../../../Interfaces/IPerson";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { IMarriage } from "../../../../../../../Interfaces/IMarriage";
import { InheritPerson, getInheritPerson } from "../InheritPerson";
import { IRequiredFiles } from "../InheritDocument";
import { IInherit } from "../../../../../../../Interfaces/IInherit";
import { checkIsDisabled } from "../../../../../../core/getDisabledHelper_Inherit";
import { DialogResponsive } from "../../../../../../core/DialogResponsive";

interface TabPanelProps {
    children?: React.ReactNode;
    index: number;
    value: number;
  }
  
function CustomTabPanel(props: TabPanelProps) {
const { children, value, index, ...other } = props;

return (
    <div
        role="tabpanel"
        hidden={value !== index}
        id={`simple-tabpanel-${index}`}
        aria-labelledby={`simple-tab-${index}`}
        {...other}
        >
        {value === index && (
            <Box sx={{ p: 3 }}>
            <Typography>{children}</Typography>
            </Box>
        )}
    </div>
    );
}

function a11yProps(index: number) {
    return {
        id: `simple-tab-${index}`,
        'aria-controls': `simple-tabpanel-${index}`,
    };
}


interface IProps {
    index: number;
    setIsOpenNew: Function;
    inheritObject: IInherit;
    currentChildren: IChildren;
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    parentPersonArray: IInheritHasPerson[];
    marriageArray: IMarriage[];
    childrenArray: IChildren[];
    setChildrenArray: Function;
}


export const ChildrenRow:React.FC<IProps> = (props) => {
    const [value, setValue] = useState(2);
    //
    const [currentChildren, setCurrentChildren] = useState(props.currentChildren);
    const [childrenPerson,setChildrenPerson] = useState(getInheritPerson(currentChildren.Children_idPerson,props.personArray));
    const [firstParent,setFirstParent] = useState<IInheritHasPerson>(getInheritPerson(currentChildren.Parent1_idPerson,props.personArray));
    //
    const [editChildren, setEditChildre] = useState(currentChildren);
    const [editChildrenPerson, setEditChildrenPerson] = useState(childrenPerson);
    //
    const [isDeath,setIsDeath] = useState(false);
    //
    const [isOpen,setIsOpen] = useState(false);

    const getRequiredFiles = () => {
        if (isDeath) {
            return [
                { Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4 },
                { Title: "Sterbeurkunde", isOptional: false, idPersonDocumentType: 1 }
            ] as IRequiredFiles[]
        } else {
            return [
                { Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4 }
            ] as IRequiredFiles[]
        }
    }

    const handleOpen = () => {
        setEditChildre(currentChildren);
        setEditChildrenPerson(childrenPerson);
        setIsOpen(true);
    }

    const handleSave = () => {
        let personArray = [...props.personArray]

        /*
        ///if: prüfen, ob weiter Elternteil angepasst werden darf
        if (editSecondParrent !== null && currentChildren.Marriage_idMarriage === null) {
            let testObject = props.personArray.find(x => x.idPerson === editSecondParrent.idPerson);

            if (testObject !== undefined) {
                personArray = personArray.map( x => x.idPerson === editSecondParrent.idPerson ? editSecondParrent : x);
            }
            else {
                personArray.push(editSecondParrent);
            }
        }

        // Löschen zweiter Person
        if (editSecondParrent === null && secondParent !== null) {
            personArray = personArray.filter(x => x.idPerson !== secondParent.idPerson);
        }
        */

        // Anpassen des Kindes
        personArray = personArray.map( x => x.idPerson === editChildrenPerson.idPerson ? editChildrenPerson : x);


        props.setPersonArray([...personArray])
        props.setChildrenArray([
            ...props.childrenArray.map(x => x.idChildren === editChildren.idChildren ? editChildren : x)
        ])
        setCurrentChildren(editChildren);
        setChildrenPerson(editChildrenPerson);
        setIsOpen(false);
    }



    const removePersonRecursive = (idPerson: number, personArray: IInheritHasPerson[], childrenArray: IChildren[]) => {
        personArray = personArray.filter(x => x.idPerson !== idPerson);
    
        childrenArray.forEach(x => {
            if (x.Parent1_idPerson === idPerson) {
                personArray = removePersonRecursive(x.Children_idPerson, personArray, childrenArray);
            }
        });
    
        return personArray;
    };


    const removePersonHasDocumentRecursive = (idPerson:number,personHasDocument:IPersonHasDocument [],childrenArray:IChildren[]) => {
        personHasDocument = personHasDocument.filter(x => x.idPerson !== idPerson)
        childrenArray.filter(x => x.Parent1_idPerson === idPerson).map(x => {
            let localArray = removePersonHasDocumentRecursive(x.Children_idPerson,personHasDocument,childrenArray)
            personHasDocument = [...localArray]
        })
        return personHasDocument;
    }


    const removeChildrenRecursive = (idPerson:number,childrenArray:IChildren[]) => {
        childrenArray = childrenArray.filter(x => x.Children_idPerson !== idPerson)
        childrenArray.filter(x => x.Parent1_idPerson === idPerson).map(x => {
            let localArray = removeChildrenRecursive(x.Children_idPerson,childrenArray)
            childrenArray = [...localArray]
        })
        return childrenArray;
    }


    /* Funktioniert ein wenig
    function removeRecursive(
        idPerson: number,
        personArray: IInheritHasPerson[],
        childrenArray: IChildren[],
        personHasDocument: IPersonHasDocument[]
    ): [IInheritHasPerson[], IChildren[], IPersonHasDocument[]] {
        let foundChild = childrenArray.find(x => x.Children_idPerson === idPerson);
    
        personArray = personArray.filter(x => x.idPerson !== idPerson);
        personHasDocument = personHasDocument.filter(x => x.idPerson !== idPerson);
    
        if (foundChild !== undefined) {
            childrenArray = childrenArray.filter(x => x.idChildren !== foundChild!.idChildren);
        }
    
        const results = childrenArray
            .filter(x => x.Parent1_idPerson === idPerson)
            .map(x => removeRecursive(x.Children_idPerson, personArray, childrenArray, personHasDocument));
    
        // Combine the results into a single array
        return results.reduce((acc, val) => {
            acc[0].push(...val[0]);
            acc[1].push(...val[1]);
            acc[2].push(...val[2]);
            return acc;
        }, [[], [], []]);
    }
    */
    


    const handleRemove = () => {
        var personArray = [...props.personArray];
        var childrenArray = [...props.childrenArray];
        var personHasDocument = [...props.personHasDocument];

        //[personArray,childrenArray,personHasDocument] = removeRecursive(childrenPerson.idPerson,personArray,childrenArray,personHasDocument);

        props.setPersonArray([...removePersonRecursive(childrenPerson.idPerson,personArray,childrenArray)])
        props.setPersonHasDocument([...removePersonHasDocumentRecursive(childrenPerson.idPerson,personHasDocument,childrenArray)])
        props.setChildrenArray([...removeChildrenRecursive(childrenPerson.idPerson,childrenArray)])
        
        
        /*
        props.setPersonHasDocument([
            ...props.personHasDocument.filter(x => x.idPerson !== removeResursive.idPerson)
        ])

        props.setPersonArray([
            ...props.personArray.filter(x => x.idPerson !== childrenPerson.idPerson)
        ])

        props.setChildrenArray([
            ...props.childrenArray.filter(x => x.idChildren !== currentChildren.idChildren)
        ])
        */
        setIsOpen(false);
    }

    const handleChange = (event: React.SyntheticEvent, newValue: number) => {
        setValue(newValue);
    };


    return(
        <>
            <DialogResponsive
                open={isOpen}
                onClose={() => {}}
                maxWidth="lg"
                actions={<>
                    <Button variant="outlined" onClick={() => setIsOpen(false)}>Abbruch</Button>
                    <Button variant="contained" disabled={checkIsDisabled(props.inheritObject,isDeath,editChildrenPerson)} onClick={handleSave}>Übernehmen</Button>
                </>}
                title={`Bearbeiten: ${childrenPerson.FirstName} ${childrenPerson.LastName}`}
            >
                <Grid container spacing={2}>
                    <Grid item sm={12}>
                        <TextField
                            sx={{mt: 2}}
                            label="Elternteil(e)"
                            select
                            value={
                                (editChildren.Parent1_idPerson === null) ? "" 
                                : (editChildren.Parent2_idPerson === null) ? editChildren.Parent1_idPerson
                                : `${editChildren.Parent1_idPerson}_${editChildren.Parent2_idPerson}`
                            }
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                let splitTest = String(event.target.value).split("_");
                                
                                if (splitTest.length === 2) {
                                    setEditChildre({
                                        ...editChildren,
                                        Parent1_idPerson: Number(splitTest[0]),
                                        Parent2_idPerson: Number(splitTest[1])
                                    })
                                } else {
                                    setEditChildre({
                                        ...editChildren,
                                        Parent1_idPerson: Number(splitTest[0]),
                                        Parent2_idPerson: null
                                    })
                                }
                            }}
                            size="small"
                            fullWidth
                        >
                            {props.marriageArray
                                .filter(x => 
                                    props.parentPersonArray.map(x => x.idPerson).indexOf(x.Person1_idPerson) > -1
                                    || props.parentPersonArray.map(x => x.idPerson).indexOf(x.Person2_idPerson) > -1
                                )
                                .map(x => {
                                    let firstPerson = props.personArray.find(y => y.idPerson === x.Person1_idPerson);
                                    let secondPerson = props.personArray.find(y => y.idPerson === x.Person2_idPerson);

                                    return(
                                        <MenuItem key={`idMarraige-${x.idMarriage}`} value={`${x.Person1_idPerson}_${x.Person2_idPerson}`}>
                                            <>{firstPerson?.FirstName} {firstPerson?.LastName} und {secondPerson?.FirstName} {secondPerson?.LastName}</>
                                        </MenuItem>
                                    )
                                })
                            }
                            {props.parentPersonArray.map(x =>
                                <MenuItem key={`idPerson-${x.idPerson}`} value={x.idPerson}>
                                    <>{x.FirstName} {x.LastName}</>
                                </MenuItem>
                            )}
                        </TextField>
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            sx={{mt: 2}}
                            label="Adoptiert"
                            select
                            size="small"
                            fullWidth
                            value={(editChildren.isAdopted) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                                setEditChildre({
                                    ...editChildren,
                                    isAdopted: (event.target.value === "true") 
                                })
                            }
                        >
                            <MenuItem key="isAdopted-true" value="true">Ja</MenuItem>
                            <MenuItem key="isAdopted-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>

                    <Grid item sm={6}>
                        <TextField
                            sx={{mt: 2}}
                            label="Zur Adoption freigegeben"
                            select
                            size="small"
                            fullWidth
                            value={(editChildren.isPutUpForAdoption) ? "true" : "false"}
                            onChange={(event: React.ChangeEvent<HTMLInputElement>) => 
                                setEditChildre({
                                    ...editChildren,
                                    isPutUpForAdoption: (event.target.value === "true") 
                                })
                            }
                        >
                            <MenuItem key="isPutUpForAdoption-true" value="true">Ja</MenuItem>
                            <MenuItem key="isPutUpForAdoption-false" value="false">Nein</MenuItem>
                        </TextField>
                    </Grid>
                </Grid>

                <InheritPerson
                    personArray={props.personArray}
                    key="child"
                    currentPerson={editChildrenPerson}
                    setCurrentPerson={setEditChildrenPerson}
                    inheritObject={props.inheritObject}
                    personHasDocument={props.personHasDocument}
                    setPersonHasDocument={props.setPersonHasDocument}
                    title=""
                    showDeathSettings
                    requiredFiles={getRequiredFiles()}
                    setIsDeath={setIsDeath}
                />
            </DialogResponsive>



            <TableRow sx={{backgroundColor: (childrenPerson.idPerson === -1) ? "#f2f2f2" : undefined }}>
                <TableCell>{props.index +1}</TableCell>
                <TableCell>
                    {(firstParent === null) ? <>ERROR</> : `${firstParent.FirstName} ${firstParent.LastName}`}
                </TableCell>

                <TableCell>
                    {`${childrenPerson.FirstName} ${childrenPerson.LastName}`}
                </TableCell>
                <TableCell>
                    <IconButton disabled={childrenPerson.idPerson === -1} onClick={handleOpen}><Edit/></IconButton>
                    <IconButton disabled={childrenPerson.idPerson === -1} onClick={handleRemove}><Delete/></IconButton>
                </TableCell>
            </TableRow>
        </>
    )
}