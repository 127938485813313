import React, { useState } from "react";
import { IInheritHasPerson } from "../../../../../../../Interfaces/IPerson";
import { IChildren } from "../../../../../../../Interfaces/IChildren";
import { InheritPerson, getInheritPerson } from "../InheritPerson";
import { Box, Button, Dialog, DialogActions, DialogContent, DialogTitle, Fade, MenuItem, TextField, Typography } from "@mui/material";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { IRequiredFiles } from "../InheritDocument";
import { checkIsDisabled } from "../../../../../../core/getDisabledHelper_Inherit";
import { IInherit } from "../../../../../../../Interfaces/IInherit";
import { IMarriage } from "../../../../../../../Interfaces/IMarriage";
import { DialogResponsive } from "../../../../../../core/DialogResponsive";


interface IProps {
    inheritObject: IInherit;
    setIsOpenNew: Function;
    parentPersonArray: IInheritHasPerson[];
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    childrenArray: IChildren[];
    setChildrenArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    marriageArray: IMarriage[];
}

export const getChildren = (isNew: boolean, idChildren: number, childrenArray: IChildren[]) => {
    let testObject = childrenArray.find(x => x.idChildren === idChildren);
    /*  Test Push Git*/
    if (isNew === false && testObject !== undefined) {
        return testObject;
    } else {
        let minId = Math.min(...childrenArray.map(x => x.idChildren)) - 1;
        if (minId > 0) {
            minId = -1;
        }
        return {
            idChildren: minId,
            Parent1_idPerson: -1,
            Parent2_idPerson: null,
            Children_idPerson: -1,
            Marriage_idMarriage: null,
            isAdopted: false,
            isPutUpForAdoption: false
        } as IChildren
    }
}


export const ChildrenAddDialog:React.FC<IProps> = (props) => {
    const [idPersonParrent, setIdPersonParrent] = useState<number|null>(null);
    const [idPersonParrentSecond, setIdPersonParrentSecond] = useState<number|null>(null);

    const [currentPage, setCurrentPage] = useState((idPersonParrent === null) ? 0 : 1);
    //
    const [currentChildren, setCurrentChildren] = useState(getChildren(true, -1, props.childrenArray));
    const [currentPerson, setCurrentPerson] = useState(getInheritPerson(-1,props.personArray,true)); 
    const [personHasDocument, setPersonHasDocument] = useState<IPersonHasDocument[]>([]);
    const [isDeath, setIsDeath] = useState(false);


    const getRequiredFiles = () => {
        if (isDeath) {
            return [
                {Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4},
                {Title: "Sterbeurkunde", isOptional: false, idPersonDocumentType: 1}
            ] as IRequiredFiles[]
        } else {
            return [
                {Title: "Geburtsurkunde", isOptional: false, idPersonDocumentType: 4}
            ] as IRequiredFiles[]
        }
    }

    const getIsDisabled = () => {
        return(
            currentPage === 0 && idPersonParrent === null
            || currentPage === 1 || currentPage === 2
            || (currentPage === 3 && checkIsDisabled(props.inheritObject,isDeath,currentPerson))
        )
    }

    const handleNext = () => {
        if (currentPage === 3 && idPersonParrent !== null) {
            let newChild = {
                ...currentChildren, 
                Parent1_idPerson: idPersonParrent,
                Parent2_idPerson: idPersonParrentSecond,
                Children_idPerson: currentPerson.idPerson
            } as IChildren
            props.setChildrenArray([
                ...props.childrenArray,
                newChild
            ])
            props.setPersonArray([
                ...props.personArray,
                currentPerson
            ])
            props.setPersonHasDocument([
                ...props.personHasDocument,
                ...personHasDocument
            ])
            props.setIsOpenNew(false);
        } else {
            setCurrentPage(currentPage+1);
        }
    }


    return(
        <>
            <DialogResponsive
                open={true}
                onClose={() => {}}
                maxWidth={(currentPage < 3) ? "sm" : "lg"}
                title="Kind hinzufügen"
                actions={<>
                    <Button variant="outlined" onClick={() => props.setIsOpenNew(false)}>Abbruch</Button>
                    <Button variant="contained" onClick={handleNext} disabled={getIsDisabled()}>{(currentPage === 3) ? "Übernehmen" : "Weiter"}</Button>
                </>}
            >
                    <Box sx={{mt: 2}}/>

                    <Fade in={currentPage === 0} mountOnEnter unmountOnExit>
                        <Box>
                            <Typography variant="body1" sx={{flaot: "center"}}>
                                Bitte wählen Sie das Elternteil/die Elternteile aus.
                            </Typography>
                            <TextField
                                sx={{mt: 2}}
                                label="Elternteil(e)"
                                select
                                value={
                                    (idPersonParrent === null) ? "" 
                                    : (idPersonParrentSecond === null) ? idPersonParrent
                                    : `${idPersonParrent}_${idPersonParrentSecond}`
                                }
                                onChange={(event: React.ChangeEvent<HTMLInputElement>) => {
                                    let splitTest = String(event.target.value).split("_");
                                    
                                    if (splitTest.length === 2) {
                                        setIdPersonParrent(Number(splitTest[0]));
                                        setIdPersonParrentSecond(Number(splitTest[1]));
                                    } else {
                                        setIdPersonParrent(Number(event.target.value));
                                        setIdPersonParrentSecond(null);
                                    }
                                }}
                                size="small"
                                fullWidth
                                required
                            >
                                {props.marriageArray
                                    .filter(x => 
                                        props.parentPersonArray.map(x => x.idPerson).indexOf(x.Person1_idPerson) > -1
                                        || props.parentPersonArray.map(x => x.idPerson).indexOf(x.Person2_idPerson) > -1
                                    )
                                    .map(x => {
                                        let firstPerson = props.personArray.find(y => y.idPerson === x.Person1_idPerson);
                                        let secondPerson = props.personArray.find(y => y.idPerson === x.Person2_idPerson);

                                        return(
                                            <MenuItem key={`idMarraige-${x.idMarriage}`} value={`${x.Person1_idPerson}_${x.Person2_idPerson}`}>
                                                <>{firstPerson?.FirstName} {firstPerson?.LastName} und {secondPerson?.FirstName} {secondPerson?.LastName}</>
                                            </MenuItem>
                                        )
                                    })
                                }
                                {props.parentPersonArray.map(x =>
                                    <MenuItem key={`idPerson-${x.idPerson}`} value={x.idPerson}>
                                        <>{x.FirstName} {x.LastName}</>
                                    </MenuItem>
                                )}
                            </TextField>
                        </Box>
                    </Fade>

                    <Fade in={currentPage === 1} mountOnEnter unmountOnExit>
                        <Box
                            display="flex"
                            height={80}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="body1" sx={{flaot: "center"}}>
                                Ist das Kind adoptiert?

                                <Box display="flex" alignItems="right" justifyContent="right" sx={{flaot: "right"}}>
                                    <Button onClick={() => {
                                        setCurrentChildren({...currentChildren, isAdopted: true })
                                        setCurrentPage(3)
                                    }}>Ja</Button>
                                    <Button onClick={() => {
                                        setCurrentChildren({...currentChildren, isAdopted: false })
                                        setCurrentPage(2)
                                    }}>Nein</Button>
                                </Box>
                            </Typography>
                        </Box>
                    </Fade>

                    <Fade in={currentPage === 2} mountOnEnter unmountOnExit>
                        <Box
                            display="flex"
                            height={80}
                            alignItems="center"
                            justifyContent="center"
                        >
                            <Typography variant="body1" sx={{flaot: "center"}}>
                                Wurde das Kind zur Adoption freigegeben?

                                <Box display="flex" alignItems="right" justifyContent="right" sx={{flaot: "right"}}>
                                    <Button onClick={() => {
                                        setCurrentChildren({...currentChildren, isPutUpForAdoption: true })
                                        setCurrentPage(3)
                                    }}>Ja</Button>
                                    <Button onClick={() => {
                                        setCurrentChildren({...currentChildren, isPutUpForAdoption: false })
                                        setCurrentPage(3)
                                    }}>Nein</Button>
                                </Box>
                            </Typography>
                        </Box>
                    </Fade>

                     <Fade in={currentPage === 3} mountOnEnter unmountOnExit>
                        <Box>
                            <InheritPerson
                                currentPerson={currentPerson}
                                setCurrentPerson={setCurrentPerson}
                                inheritObject={props.inheritObject}
                                personArray={props.personArray}
                                personHasDocument={personHasDocument}
                                setPersonHasDocument={setPersonHasDocument}
                                title=""
                                showDeathSettings
                                requiredFiles={getRequiredFiles()}
                                setIsDeath={setIsDeath}
                            />
                        </Box>
                     </Fade>
            </DialogResponsive>

        </>
    )
}