import React, { useCallback, useRef, useState } from "react";
import { Card, CardContent, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { DocumentRow } from "./DocumentRow";
import { UploadFile } from "@mui/icons-material";
import { useSelector } from "react-redux";
import { useDropzone } from "react-dropzone";
import { IDocument } from "../../Interfaces/IDocument";
import { State } from "../../redux/mainReducer";
import { IMIMEType } from "../../Interfaces/IMIMEType";
import { staticMimeType } from "../../StaticArraies/staticMimeType";
import { useTranslation } from "react-i18next";
import { checkTranslation } from "../core/standardFunctions";



interface IProps {
    documentArray: IDocument[];
    setDocumentArray: Function;
    allowUpload: boolean;
    allowRemove: boolean;
    title: string;
    children?: React.ReactElement;
}


export const DocumentOverview:React.FC<IProps> = (props) => {
    const { t } = useTranslation();

    const mimeTypeArray = staticMimeType;
    const [enableUpload, setEnableUpload] = useState(false);
    //
    const inputUpload = useRef<HTMLInputElement | null>(null);


    async function fileToBase64(file:File) {
        let result_base64 = await new Promise((resolve) => {
            let fileReader = new FileReader();
            fileReader.onload = (e) => resolve(fileReader.result);
            fileReader.readAsDataURL(file);
        });
        return String(result_base64).split(",")[1];
    }

    const handleFilesUpload = async (acceptedFiles:File[]) => {
        let uploadArray:IDocument[] = [];
        let minIdOffset = -1;

        if (props.documentArray.length > 0) {
            let minIdArray = Math.min(...props.documentArray.map(x => x.idDocument));

            if (minIdArray <= minIdOffset) {
                minIdOffset = minIdArray -1;
            }
        }

        let index = 0;

        
        for (let currentFile of acceptedFiles) {
            let fileName:string = String(currentFile["name"]);
            let mimeType: IMIMEType | undefined = mimeTypeArray.find(y => y.MIMEType === currentFile["type"]);
            
            if (mimeType !== undefined) {
                uploadArray.push({
                    idDocument: minIdOffset - index,
                    FileName: fileName,
                    idMIMEType: mimeType.idMIMEType,
                    Data: await fileToBase64(currentFile),
                    Created_at: new Date().toISOString()
                } as IDocument)
            }
            index = index + 1;
        }
        
        props.setDocumentArray([
            ...props.documentArray,
            ...uploadArray
        ])

    }

    const handleNormalUpload = (files:null|FileList) => {
        if (files !== null) {
            let acceptedFiles:File[] = Array.from(files);
            handleFilesUpload(acceptedFiles);
        }
    }

    const onDrop = useCallback( (acceptedFiles:File[]) => { 
        //setNewFileArray(acceptedFiles);
        //callBackDriver(acceptedFiles);
        //setAddNew(false);
        handleFilesUpload(acceptedFiles);
        setEnableUpload(false);
     }, [props.documentArray] );

    const { getRootProps, getInputProps, isDragActive } = useDropzone( { 
        onDrop,
        noClick: true,
    } );

    const dropZoneTSX = () => {
        return(
            <div>
                <input {...getInputProps()} accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))}/>
                <Card>
                    <CardContent>
                        <Typography variant="body2">
                            {checkTranslation(t, "here_drag", "Die Dokumente können per Drag & Drop hochgeladen werden. ")}
                        </Typography>
                        {checkTranslation(t, "document_allowed_type", "Folgende Datenarten sind erlaubt:")}
                        <br />
                        <Table>
                            <TableHead>
                                <TableRow>
                                    <TableCell>{checkTranslation(t, "document_type", "Datenart")}</TableCell>
                                    <TableCell>{checkTranslation(t, "document_extension", "Dateiendung")}</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {mimeTypeArray.map(x => 
                                    <TableRow>
                                        <TableCell>{x.MIMEType}</TableCell>
                                        <TableCell>{x.FileExtension}</TableCell>
                                    </TableRow>
                                )}
                            </TableBody>
                        </Table>
                    </CardContent>
                </Card>
            </div>
        )
    }

    return(
        <div {...getRootProps()}>
            <input 
             hidden 
             ref={inputUpload} 
             type="file" 
             accept={String(mimeTypeArray.map(x => x.MIMEType).join(", "))} 
             onChange={(event: React.ChangeEvent<HTMLInputElement>) => handleNormalUpload(event.target.files)} 
            />

            <Typography variant="h5" sx={{mb: 2}}>
                {props.title} 
                {(props.allowUpload) && 
                    <IconButton 
                     title={checkTranslation(t, "upload_selection", "Datei hochladen")}
                     onClick={() => (inputUpload !== null) && inputUpload.current?.click()} 
                     sx={{float: "right"}}
                    >
                        <UploadFile/>
                    </IconButton>
                }
                {(props.allowUpload) && <><br/><Typography variant="caption">{checkTranslation(t, "upload_drag", "Die Dokumente können per Drag & Drop hochgeladen werden. ")}</Typography></>}
                
            </Typography>

            {((isDragActive || enableUpload) && props.allowUpload) && dropZoneTSX()}

            { (props.children === undefined) ?
                <>
                    <Table>
                        <TableHead>
                            <TableRow>
                                <TableCell>{checkTranslation(t, "number", "Nr.")}</TableCell>
                                <TableCell>{checkTranslation(t, "document_name", "Dateiname")}</TableCell>
                                <TableCell>{checkTranslation(t, "actions", "Aktionen")}</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {props.documentArray.map(x =>
                                <DocumentRow
                                    key={`idDocument-${x.idDocument}`}
                                    documentObject={x}
                                    documentArray={props.documentArray}
                                    setDocumentArray={props.setDocumentArray}
                                    allowRemove={props.allowRemove}
                                />
                            )}
                        </TableBody>
                    </Table>
                </>
                
                : <>{props.children}</>
            }


        </div>
    )

}