import { Grid, CircularProgress } from "@mui/material";
import React from "react";


export const CustomCircularProgress: React.FC = () => {
  return (
    <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{ minHeight: '50vh' }}>
                <Grid item xs={3}>
                    <CircularProgress size={300} />
                </Grid>
            </Grid>
        </>
  );
}