import { IInherit } from "../../Interfaces/IInherit";
import {IInheritHasPerson} from "../../Interfaces/IPerson";



export function checkIsDisabled(inheritObject:IInherit, isDead: boolean, personObject:  IInheritHasPerson | null) {
    if(personObject == null)
    {
        return true
    }
    else if (inheritObject.idPerson !== personObject.idPerson) {
        return(
            personObject.idGender < 0
            || personObject.FirstName === ""
            || personObject.LastName === ""
            || (isDead && personObject.DateOfDeath === null)
        )
    }

    else if(isDead && inheritObject.idPerson !== personObject.idPerson) // Wenn die Verwandte Person tot ist
    {
        return(
            personObject.idGender < 0
            || personObject.FirstName === ""
            || personObject.LastName === ""
            || personObject.Birthday === ""
            || personObject.DateOfDeath === null
        )
    }

    else if(isDead) // Wenn die Person Erblasser ist
    {
        return(
            personObject.idGender < 0
            || personObject.FirstName === ""
            || personObject.LastName === ""
            || personObject.Birthday === ""
            || personObject.DateOfDeath === null
            || personObject.Street === ""
            || personObject.StreetNr === ""
            || personObject.idPostcode === null
            || personObject.idPostcode === -1
        )
    }
    else {
        return (
            personObject.idGender < 0
            || personObject.FirstName === ""
            || personObject.LastName === ""
            || personObject.Birthday === ""
            || personObject.Street === ""
            || personObject.StreetNr === ""
            || personObject.idPostcode === null
            || personObject.idPostcode === -1
        ) // Wenn die Verwandte person noch lebt
    }
}
