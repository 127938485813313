import React, { useState } from "react";
import { Button, IconButton, Table, TableBody, TableCell, TableHead, TableRow, Typography } from "@mui/material";
import { Add } from "@mui/icons-material";
import { MarriageRow } from "./MarriageRow";
import { IInheritHasPerson } from "../../../../../../../Interfaces/IPerson";
import { IMarriage } from "../../../../../../../Interfaces/IMarriage";
import { IPersonHasDocument } from "../../../../../../../Interfaces/IPersonHasDocument";
import { IInherit } from "../../../../../../../Interfaces/IInherit";

interface IProps {
    currentPerson: IInheritHasPerson;
    inheritObject: IInherit;
    personArray: IInheritHasPerson[];
    setPersonArray: Function;
    personHasDocument: IPersonHasDocument[];
    setPersonHasDocument: Function;
    marriageArray: IMarriage[];
    setMarriageArray: Function;
}


export const MarriageOverview:React.FC<IProps> = (props) => {
    const [isNewOpen,setIsNewOpen] = useState(false);
        //<IconButton onClick={() => setIsNewOpen(true)} sx={{float: "right"}}><Add/></IconButton>
    return(
        <>
            <Typography variant="h5">
                Auflistung der Ehe(n)
            </Typography>

            <Typography variant="body1" sx={{mt: 2, mb: 3}}>
                Die Erfassung aller Ehen ist wichtig, um die etweigen Ansprüche von (ehemaligen) Ehepartnern, Witwen oder Witwern sowie möglichen Kindern aus vorherigen Ehen zu klären. 
            </Typography>
            


            <Table>
                <TableHead>
                    <TableRow>
                        <TableCell>Nummer</TableCell>
                        <TableCell>Verheiratet</TableCell>
                        <TableCell>Geschieden</TableCell>
                        <TableCell>Name</TableCell>
                        <TableCell>Aktionen</TableCell>
                    </TableRow>
                </TableHead>

                <TableBody>
                    {(isNewOpen) &&
                        <MarriageRow
                            key="marriage-new"
                            idMarriage={-1}
                            index={0}
                            inheritObject={props.inheritObject}
                            inherit={props.currentPerson}
                            marriageArray={props.marriageArray}
                            personArray={props.personArray}
                            personHasDocument={props.personHasDocument}
                            setMarriageArray={props.setMarriageArray}
                            setPersonArray={props.setPersonArray}
                            setPersonHasDocument={props.setPersonHasDocument}
                            setIsOpenNew={setIsNewOpen}
                        />
                    }

                    {props.marriageArray
                    .filter(x => x.Person1_idPerson === props.currentPerson.idPerson)
                    .sort((a,b) => new Date(a.DateOfMarriage!) > new Date(b.DateOfMarriage!) ? 1 : -1)
                    .map((x,i) => 
                        <MarriageRow
                            key={`marriage-${x.idMarriage}`}
                            idMarriage={x.idMarriage}
                            index={i}
                            inheritObject={props.inheritObject}
                            inherit={props.currentPerson}
                            marriageArray={props.marriageArray}
                            personArray={props.personArray}
                            personHasDocument={props.personHasDocument}
                            setMarriageArray={props.setMarriageArray}
                            setPersonArray={props.setPersonArray}
                            setPersonHasDocument={props.setPersonHasDocument}
                        />
                    )}
                </TableBody>
                <br></br>
                <Button variant="contained" onClick={() => setIsNewOpen(true)} sx={{float: "left"}}>Ehe(n) hinzufügen</Button>
            </Table>
        </>
    )
}