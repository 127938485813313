import {Alert, Grid, Typography} from "@mui/material";
import React, {useEffect} from "react";
import {useTranslation} from "react-i18next";
import {ICompanyFormation} from "../../Interfaces/ICompanyFormation";
import {IShareHolderFullObject} from "../../Interfaces/IShareHolder";
import {ICompanyFormationShare} from "../../Interfaces/ICompanyFormationShare";
import {ICompanyFormationHasShareHolder} from "../../Interfaces/ICompanyFormationHasShareHolder";
import {IConfig} from "../../Interfaces/IConfig";


interface IProps {
    isTranslationEnabled?: boolean;
}

const Send: React.FC<IProps> = (props) => {

    useEffect(() => {
        localStorage.setItem('wasHereBefore', "0");
        //Runs only on the first render
    }, []);

    const {t} = useTranslation();

    const checkTransaltionEnabled = () => {
        if (!props.isTranslationEnabled || props.isTranslationEnabled === undefined) {
            return false
        }
        if (props.isTranslationEnabled) {
            return true
        }
    }

    return (
        <>
            <Grid
                container
                spacing={0}
                direction="column"
                alignItems="center"
                justifyContent="center"
                style={{minHeight: '100vh'}}
            >
                <Typography
                    variant="h3">{(checkTransaltionEnabled()) ? t("ra_send") : "Ihre Daten wurden erfolgreich übermittelt!"}</Typography>
                <Typography
                    variant="h5">{(checkTransaltionEnabled()) ? t("ra_send_confirmation") : "Sie erhalten in den nächsten 10 Minuten eine Bestätigungsmail aus unserem System."}</Typography>

                <Alert severity="info">
                    Sie können die Seite nun schließen
                </Alert>
            </Grid>
        </>
    )
}
export default Send;