import { Box, Slide } from "@mui/material";
import React from "react";


interface IProps {
    appear?: boolean;
    
    pageNumber: number;
    currentPage: number;
    lastCurrentPage: number;
    children: React.ReactNode;
}

export const CustomeSlide:React.FC<IProps> = (props) => {
    return(
        <Slide 
            appear={(props.appear === undefined || props.appear === true)} 
            direction={(props.pageNumber > props.lastCurrentPage) ? "left" : "right"} 
            in={props.currentPage === props.pageNumber} 
            mountOnEnter unmountOnExit
        >
            <Box>
                {props.children}
            </Box>
        </Slide>
    )
}