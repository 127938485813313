import { Box, Button, Typography } from "@mui/material";
import React from "react";


interface IProps {
    handleBack: Function;
    handleNext: Function;
}


export const PurchaseContractStart:React.FC<IProps> = (props) => {
    return(
        <>
            <Box sx={{ mt: 5 }} />
            <Typography variant="h4" sx={{ mb: 3 }}>Kaufvertrag</Typography>

            <Typography>
                Herzlich willkommen! 
                <br/>Sie möchten ein (bebautes oder unbebautes) Grundstück, eine Eigentumswohnung oder ein Erbbaurecht verkaufen? Mit unserem Dialog können Sie die Vorbereitung Ihres Kaufvertrags in die Wege leiten. Nachdem Sie Ihre Daten eingereicht haben, erhalten Sie zügig einen Vertragsentwurf.
                <p />
                Brauchen Sie zwischendurch eine Pause? Sie können Ihre Eingaben jederzeit über das Feld <i>Sichern</i> exportieren und speichern. 
                Setzen Sie Ihre Eingaben dann über die Klickfläche <i>Wiederherstellung</i> fort. 
            </Typography>

            <Box sx={{ display: 'flex', flexDirection: 'row', pt: 2 }}>
                <Box sx={{ flex: '1 1 auto' }} />
                <Button onClick={() => props.handleNext()}  variant="contained">
                    Weiter
                </Button>
            </Box>
        </>
    )
}