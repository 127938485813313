import React from "react";
import { IRealEstate } from "../../../../../../../Interfaces/IRealEstate";
import { Grid, Typography } from "@mui/material";
import { checkString } from "../../../../../../core/standardFunctions";
import { IMortgageFullObject } from "../../../../../../../Interfaces/IMortgage";


interface IProps {
    mortgageObject: IMortgageFullObject;
}



export const RealEstateSummary:React.FC<IProps> = (props) => {


    return(
        <>
            {props.mortgageObject.RealEstateLandRegistryData == "yes" ?
                <Grid container spacing={2} sx={{ml: 0, mt: 2}}>
                    <Typography sx={{mt: 5}} variant="h6">Grundbuchinformationen</Typography>
                    
                        <>
                            <Grid item sm={12}><hr /></Grid>

                            <Grid item sm={4}><b>Amtsgericht:</b></Grid>
                            <Grid item sm={8}> {props.mortgageObject.RealEstate.DistrictCourt?.Court}</Grid>

                            <Grid item sm={4}><b>Grundbuch:</b></Grid>
                            <Grid item sm={8}> {checkString(props.mortgageObject.RealEstate.LandRegister)}</Grid>

                            <Grid item sm={4}><b>Blatt:</b></Grid>
                            <Grid item sm={8}>{checkString(props.mortgageObject.RealEstate.SheetLandRegister)}</Grid>
                            
                            <Grid item sm={4}><b>Flurstück:</b></Grid>
                            <Grid item sm={8}>{checkString(props.mortgageObject.RealEstate.LandParcel)}</Grid>

                            <Grid item sm={4}><b>Flur:</b></Grid>
                            <Grid item sm={8}>{checkString(props.mortgageObject.RealEstate.Parcel)}</Grid>                       

                        </>
                </Grid>
                :
                <Grid container spacing={2} sx={{ml: 0, mt: 2}}>
                    <Typography sx={{mt: 5}} variant="h6">Objekt</Typography>
                
                    <>

                        <Grid item sm={12}><hr /></Grid>

                        <Grid item sm={4}><b>Straße:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.RealEstate.Street}</Grid>

                        <Grid item sm={4}><b>Adresszusatz:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.RealEstate.StreetAditional}</Grid>

                        <Grid item sm={4}><b>Hausnummer:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.RealEstate.StreetNr}</Grid>

                        <Grid item sm={4}><b>Postleitzahl:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.RealEstate.Postcode}</Grid>

                        <Grid item sm={4}><b>Ort:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.RealEstate.City}</Grid>

                        <Grid item sm={4}><b>Breitengrad:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.RealEstate.Latitude}</Grid>

                        <Grid item sm={4}><b>Längengrad:</b></Grid>
                        <Grid item sm={8}>{props.mortgageObject.RealEstate.Longitude}</Grid>

                    </>
            </Grid>
            }
        </>
    )
}